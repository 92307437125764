$button-height: 42px;

.dropdown:global(.show) {
  .dropdownToggle {
    border-color: #4F46E5;
  }
}

.toggle {
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon {
  position: relative;
  font-size: 22px;
}

.count {
  position: absolute;
  top: -2px;
  left: calc(100% - 11px);
  padding: 2px;
  min-width: 14px;
  height: 14px;
  font-size: 8px;
  font-weight: 700;
  line-height: 10px;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  background-color: #ff0000;
  text-align: center;
}

.dropdownMenu {
  width: 600px;
  min-width: 600px;
}

.dropdownItem {
  display: flex;
  padding: 10px;
  background-color: rgba(115, 134, 201, .2);
  border-bottom: 1px solid #CCCCCC;
  cursor: pointer;
}

.wasRead {
    background-color: transparent;
    cursor: default;
  }

.dropdownItemIcon {
  margin-right: 10px;
}

.colorRed {
  color: #e36767;
}

.colorBlue {
  color: #7386c9;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  padding: 1px 10px 10px;
  border-bottom: 1px solid #CCCCCC;

  button {
    padding: 0;
  }
}

.dropdownItemTitle {
  font-size: 14px;
  color: #000000;
}

.dropdownItemDate {
  font-size: 11px;
  color: #6e6e6e;
}

.dropdownFooter {
  padding: 10px 10px 1px;
  text-align: center;

  a {
    cursor: default;
    pointer-events: none;
  }
}

.dropdownItemInfo {
  flex: 1;

  span {
    display: block;
  }
}