.issueCard {
  max-width: 336px;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .15);
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, .05);
  font-family: Inter, sans-serif;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: scale(1.03);
    transition: transform 0.2s ease-in-out;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  h4 {
    color: #020036;
    font-size: 16px;
    font-weight: 600;
  }

  .tag {
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    height: fit-content;
  }

  .network {
    color: #1A237E;
    background-color: #BBDEFB;
  }
  .reported {
    color: #B71C1C;
    background-color: #FFCDD2;
  }
  .device {
    color: #004D40;
    background-color: #B2DFDB;
  }
  .application{
    color: #827717;
    background-color: #F0F4C3;
  }
}

.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.time, .reference {
  width: 100%;
  text-wrap: nowrap;
  text-align: right;
  font-size: 12px;
  color: #5B626B;
}

.reference {
  text-align: initial;
}
