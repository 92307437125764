html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: initial;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: GT Walsheim Pro Bold;
  src: url("../../fonts/GTWalsheimPro-Bold.ttf");
}

@font-face {
  font-family: GT Walsheim Pro Regular;
  src: url("../../fonts/GTWalsheimPro-Regular.ttf");
}

@font-face {
  font-family: GT Walsheim Pro Light;
  src: url("../../fonts/GTWalsheimPro-Light.ttf");
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

html, body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
}

#layout-wrapper, .main-content {
  min-height: 100%;
  height: 100%;
}

p {
  margin: 0;
}

.center {
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: #b3b3b3;
}

.button-lg {
  margin-top: 60px;
  padding: 35px 80px;
  font-size: 30px;
}

.button-md {
  font-size: 16px;
  padding: 6.5px 30px;
  cursor: pointer;
}

.button-light {
  background-color: #6D69CF;
}

.button-transparent {
  border: 1px solid #6D69CF;
  color: #6D69CF;
}

.button-white {
  background-color: #fff;
  color: #3C37BE;
  border: 2px solid #3C37BE;
}

.button-chrome {
  position: relative;
  min-height: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 9px;
  padding: 6px 42px 6px 20px;
  letter-spacing: 1px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 100%;
    top: 0;
    right: 0;
    padding: 8px;
    background-color: #B8B5FF;
    border-radius: 50%;
  }
}

.button-submit {
  padding: 10px 30px;
  background-color: #3C37BE;
  color: #fff;
  font-size: 16px;
}

.button-submit[disabled] {
  color: #999999;
}

.heading {
  font-size: 1.8rem;
}

.subheading {
  font-size: 16px;
  padding-top: 8px;
}

.spinner-img img {
  width: 85px;
}

.m-auto {
  margin: 0 auto;
}

.text-right {
  text-align: right;
}

button {
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
}

.header {
  padding: 16px 0 0;
  border-bottom: 1px solid #CECECE;
  margin-bottom: 16px;

  p {
    font-size: 10px;
    font-weight: 500;
    text-align: right;
  }

  img {
    width: 154px;
    margin-bottom: 16px;
  }

  &.result {
    .container {
      max-width: 960px;
    }
  }
}

.footer {
  border-top: 1px solid #D8D8D8;
  padding: 15px 10px;

  a {
    display: inline-block;

    img {
      display: block;
    }
  }

  &__copyright {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 8px;
  }

  .button-chrome {
    font-size: 9px;
    padding: 6px 42px 6px 20px;
    color: #FFFFFF;
    border-radius: 30px;
    letter-spacing: 1px;
  }
}

.hubble_logo_container img {
  width: 100px;
}

.page-content {
  flex: 1;
  min-height: 100%;
  background-color: #ececf1;
}

.page-start,
.page-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;

  .button {
    display: inline-flex;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border-radius: 40px;
    min-width: 150px;
    align-items: center;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    min-height: 50px;

    &:focus {
      outline: none;
    }
  }

  .button-dark {
    background-color: #3C37BE;
  }

  .button-transparent {
    border: 1px solid #6D69CF;
    color: #6D69CF;
  }

  .button-white {
    background-color: #fff;
    color: #3C37BE;
    border: 2px solid #3C37BE;
  }

  .button-submit {
    padding: 10px 30px;
    background-color: #3C37BE;
    color: #fff;
    font-size: 16px;
  }

  .button-submit[disabled] {
    color: #999999;
  }
}

.page-start {
  flex: 1;
}

.page-start__img {
  //height: 345px;
  //width: 345px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
}

.page-start__img img {
  max-width: 100%;
  height: auto;
}

.page-testing {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .carousel .slick-dots li {

    button {
      color: #C4C4C4;
    }

    &.slick-active {
      button {
        color: #6D69CF;
      }
    }
  }
}

.page-testing__sub-gif {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 24px;
}

.page-result {
  .container {
    max-width: 960px;
  }

  p {
    margin: 0;
  }

  .button-dark {
    background-color: #3C37BE;
  }
}

.carousel {
  background-color: #F3F3F3;
  padding: 30px 0;
}

.carousel__item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.carousel__img {
  width: 64%;
  border-radius: 10px;
}

.carousel__heading, .carousel__text {
  font-size: 16px;
}

.carousel__heading {
  font-weight: 600;
  padding-bottom: 8px;
  font-size: 20px;
}

.carousel__text {
  padding-bottom: 30px;
}

.carousel .slick-dots li button {
  &::before {
    display: none;
  }

  &::after {
    display: none;
  }

  background-color: #C4C4C4;
  color: #212529;
  overflow: hidden;
}

.carousel .slick-dots li.slick-active button {
  background-color: #6D69CF;
  color: #fff;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
}

.slick-dots li button {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 50%;
  border: none;
  outline: none;
  line-height: 20px;
  font-size: 13px;
}

.slick-dots li:nth-last-child(1) button {
  margin-right: 0;
}

.side-text {
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  color: #979797;
}

.page-result .subheading {
  padding-bottom: 8px;
}

.page-result_mid {
  margin-top: 25px;
  margin-bottom: 46px;
}

.results-table {
  padding-bottom: 20px;
  border-bottom: 1px solid #cecece;
}

.results-table__heading {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  color: #7d7d86;
  padding: 8px 0;
}

.results-table__details {
  display: flex;
  flex-wrap: wrap;
}

.results-table__details p {
  width: 50%;
  font-size: 16px;
  padding: 8px 10px 8px 25px;
  position: relative;
}

.results-table__details p:before {
  content: '';
  width: 14px;
  height: 24px;
  position: absolute;
  border-radius: 15px;
  left: 0;
  top: 5px;
}

.results-table__details p.green:before {
  background-color: #5EE101;
}

.results-table__details p.yellow:before {
  background-color: #EED602;
}

.results-table__details p.red:before {
  background-color: red;
}

.results-table__speed span:nth-child(1) {
  font-size: 22px;
}

.results-table__speed span:nth-child(2) {
  font-size: 15px;
  margin-left: 10px;
}

.results-table__col {
  display: flex;
  flex-direction: column;
}

.results-table__speed {
  display: flex;
  align-items: center;
  margin-top: auto;

  .wifi-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-left: auto;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background-color: #feba00;

    &.good {
      background-color: #63ae63;
    }
  }
}

@font-face {
  font-family: "Material Design Icons";
  src: url("../../fonts/materialdesignicons-webfont.eot?v=4.4.95");
  src: url("../../fonts/materialdesignicons-webfont.eot?#iefix&v=4.4.95") format("embedded-opentype"), url("../../fonts/materialdesignicons-webfont.woff2?v=4.4.95") format("woff2"), url("../../fonts/materialdesignicons-webfont.woff?v=4.4.95") format("woff"), url("../../fonts/materialdesignicons-webfont.ttf?v=4.4.95") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-wifi::before {
  content: "\F5A9";
}

.mdi-wifi-strength-4-alert::before {
  content: "\F928";
}

#advance-tet-result {
  margin-top: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.results-table__advanced {
  text-align: right;
  font-size: 22px;
  font-weight: 500;

  button:focus {
    outline: none;
  }
}

.results-table__col.last_column {
  margin-bottom: 20px;
}

.results-table__advanced button {
  color: #6D69CF;
  padding-right: 30px;
  background: transparent;
  outline: none;
  position: relative;
  font-size: 18px;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #6c69cf transparent transparent transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    margin: auto;
  }

  &.opened:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #6c69cf;
    border-top: transparent;
  }
}

.articles {
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.articles__item {
  display: block;
  margin-bottom: 30px;
}

.articles__img {
  width: 100%;
  max-height: 240px;
  object-fit: cover;
}

.articles__heading {
  color: #3C37BE;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  line-height: 30px;
}

.articles__text {
  font-size: 15px;
  color: #000000ab;
}

.help-form {
  background-color: #6D69CF;
  color: #fff;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.help-form .survey-slider-block {
  position: relative;
  overflow: hidden;
}

.help-form .connection-carousel {
  padding-bottom: 0px;

  .button {
    text-transform: initial;
    font-weight: 600;
  }

  .survey-slider-block {
    position: relative;
    overflow: hidden;
  }
}

.help-form .connection-carousel-heading {
  font-size: 30px;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 30px;
}

.help-form .answers-holder {
  display: flex;
  flex-wrap: wrap;
}

.help-form .answers-holder > div.answer-holder {
  padding: 0 14px 28px;
  width: 50%;
}

.help-form .answers-holder > div.answer-holder button {
  width: 100%;
}

.help-form .slick-dots li button {
  background-color: #9592dc;
  color: #9592dc;
  font-size: 0;
}

.help-form .slick-dots li.slick-active button {
  background-color: #aaa8e3;
  color: #aaa8e3;
}

.help-form__info {
  text-align: left;

  &.submitted {
    text-align: center;
    font-size: 24px;
    padding: 30px 0;
    justify-content: center;
  }
}

.help-form__info textarea,
.help-form__info input {
  width: 100%;
  border: none;
  border-radius: 5px;
}

.help-form__info textarea {
  min-height: 100px;
}

.help-form__info input {
  height: 40px;
}

.help-form__info .subheading {
  font-weight: 700;
  padding: 8px 0;
}

.help-form__info button {
  margin-top: 30px;
}

@media (min-width: 2560px) {
  .container {
    max-width: 1600px;
  }
}

@media (max-width: 1200px) {
  /*.heading {
      font-size: 40px;
  }

  .subheading {
      font-size: 22px;
  }

  .button-lg {
      margin-top: 30px;
      padding: 20px 65px;
      font-size: 22px;
  }*/

  .page-start {
    padding-bottom: 30px;
  }

  .footer {
    padding-top: 30px;
  }

  /*    .side-text,
      .results-table__heading {
          font-size: 14px;
      }

      .results-table__details p {
          font-size: 17px;
      }

      .results-table__speed span:nth-child(1) {
          font-size: 35px;
      }

      .results-table__speed span:nth-child(2) {
          font-size: 16px;
      }

      .results-table__advanced {
          font-size: 17px;
      }*/

  .results-table__advanced:after {
    border-width: 10px 5px 0 5px;
  }

  .results-table__advanced.opened:after {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #6c69cf;
    border-top: transparent;
  }

  .button-md {
    font-size: 14px;
    padding: 6.5px 30px;
  }

  .articles__heading {
    font-size: 24px;
  }

  .articles__text {
    font-size: 15px;
  }

  .articles__img {
    margin-bottom: 10px;
  }

  .articles__heading {
    padding-bottom: 5px;
  }

  .articles__item {
    margin-bottom: 20px;
  }

  .slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0;
    font-size: 10px;
    line-height: 15px;
  }

  // .help-form .connection-carousel {
  //   padding-bottom: 35px;
  // }

}

@media(max-width: 1025px) {
  .hubble_logo_container,
  .button-chrome {
    display: none;
  }
}

@media (max-width: 992px) {
  .or-0 {
    order: 0;
  }

  .or-1 {
    order: 1;
  }

  .or-2 {
    order: 2;
  }

  .side-text {
    text-align: left;
    padding-bottom: 15px;
  }

  .articles__img {
    height: 180px;
  }

  .articles__heading {
    font-size: 18px;
  }

  .help-form .connection-carousel-heading {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 1.8rem;
  }
}

@media(max-width: 991px) {

  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .results-table {
    &__details {
      flex-direction: row;
      flex-wrap: wrap;

      p {
        width: 50%;
      }
    }

    &__col {
      .results-table__col {
        margin-bottom: 20px;
      }
      margin-bottom: 20px;
    }
  }

  #advance-tet-result {
    margin-top: 0;
  }

}

@media (max-width: 767px) {
  .container {
      max-width: 100%;
  }

  .articles__img {
    height: auto;
    max-height: 180px;
  }

  .help-form .connection-carousel-heading {
    font-size: 1.6rem;
  }
}

@media(max-width: 599px) {

  .header {
    img {
      width: 120px;
    }
  }

  .page-start__img {
    img {
      max-width: 80%;
    }
  }

  .page-testing {
    .carousel {
      padding-top: 16px;
      padding-bottom: 16px;

      &__heading {
        margin-top: 8px !important;
        font-size: 16px;
      }

      &__text {
        padding-bottom: 8px;
        font-size: 12px;
      }
    }
  }

  .spinner-img img {
    width: 50px;
  }

  .carousel__img {
    width: 100%;
  }

  .heading {
    font-size: 1.2rem;
  }

  .subheading {
    font-size: 12px;
  }

  .hubble_logo_container img {
    width: 116px;
  }

  .button-chrome {
    min-height: 28px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 32px 5px 12px;
    font-size: 10px;

    span {
      width: 28px;
      padding: 8px;
    }
  }

  .help-form {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .results-table__heading {
    font-size: 11px;
    letter-spacing: 1px;
  }

  .results-table__details p {
    font-size: 11px;
    padding-right: 8px;
    padding-left: 15px;

    &:before {
      width: 6px;
      height: 18px;
      top: 7px;
    }
  }

  .results-table__speed span:nth-child(1) {
    font-size: 20px;
  }

  .results-table__speed span:nth-child(2) {
    font-size: 14px;
  }

  #advance-tet-result {
    margin-top: 16px;
    padding-left: 0;
  }

  .button-md {
    min-width: auto;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 14px;
  }

  .articles__heading {
    font-size: 15px;
    line-height: 24px;
  }

  .articles__text {
    font-size: 12px;
  }

  .page-testing__sub-gif {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .footer {
    padding: 15px 20px 10px;

    &__copyright {
      letter-spacing: unset;
    }
  }
}

@media(max-width: 575px) {
  .help-form .answers-holder {
    flex-direction: column;
    align-items: center;
  }

  .help-form .connection-carousel-heading {
    font-size: 1.2rem;
  }

  .help-form .answers-holder > div.answer-holder {
    width: 100%;
    max-width: 90%;
    padding: 0 0 20px;

    button {
      min-height: 40px;
    }
  }

}

@keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 7s infinite;
  -moz-animation: flickerAnimation 7s infinite;
  -o-animation: flickerAnimation 7s infinite;
  animation: flickerAnimation 7s infinite;
}

.testing-error-container{
  background-color: #f3f3f3;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  .heading {
    font-weight: bold;
  }
  .subheading{
    max-width: 650px;
    padding: 30px 0;
  }
}

/*# sourceMappingURL=custom-styles.css.map */
