.card {
  height: 100%;
  background: #FFFFFF;
  box-shadow: 12px 12px 32px rgba(74, 45, 197, .07);
  border: none;
  border-radius: 16px;
}

.card_header {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #10298E;
  background-color: transparent;
  border-bottom: 1px solid #D0D5DD;
  text-transform: uppercase;
}

.card_header span {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

.card_subtitle {
  min-height: 24px;
  font-size: 16px;
  line-height: 24px;
}

.card_body {
  padding: 16px;
}

.card_item {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
}

.card_item span {
  margin-left: 2px;
  text-transform: lowercase;
}

.card_item_status {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-right: 16px;
  border-radius: 100%;
  background-color: #D0D5DD;
}

.card_item_status:global.good {
  background-color: #22D88F;
}
.card_item_status:global.bad {
  background-color: #F31C1C;
}
.card_item_status:global.poor {
  background-color: #F31C1C;
}
.card_item_status:global.decent {
  background-color: #E1B107;
}

.card_col_title {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 36px;
}

.card_col_value {
  font-size: 42px;
  line-height: 62px;
  color: #10298E;
}

.card_col_value span {
  margin-left: 4px;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.card_list_title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--bs-black)
}