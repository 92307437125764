

.averages {
  --block-shadow: 0px 0px 32px rgba(74, 45, 197, 0.07);
  display: flex;
  gap: 8px;
  margin-bottom: 1.5rem;
}

.block {
  display: flex;
  align-items: center;
  color: #020036;
  gap: 8px;
}

.label {
  width: 100%;
  max-width: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 12px 12px 8px;
  font-size: 14px;
  background-color: #F2F1FF;
  border-radius: 16px;
  text-align: center;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.icon img {
  display: block;
  max-width: 100%;
  height: 100%;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #020036;
}

.valueBlock {
  height: 70px;
  padding: 8px;
  font-size: 14px;
  background-color: #4F46E5;
  color: white;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.valueBlockTitle {
  font-size: 12px;
}

.value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.value span {
  font-size: 14px;
}
@media(min-width: 1600px) {
  .averages {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .averages {
    justify-content: flex-start;
  }

  .label {
    width: 100%;
    max-width: 190px;
    margin-bottom: 10px;
  }

  .valueBlock {
    margin-left: 0;
  }
}
