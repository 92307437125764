.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 10px;
}

.value {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #5B626B;
}
