.formWrapper {
  max-width: 400px;
}

.stripeFormElements {
  margin-bottom: 40px;

  :global(.form-group) label {
    margin-bottom: 5px;
  }
}

.stripeFormActions {
  display: flex;
  justify-content: space-between;
}

.stripeCardElementWrapper {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
}

.customElementGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.customElementGroupTop {
  grid-column-start: 1;
  grid-column-end: 3;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.customElementGroupBottomLeft {
  border-top: none;
  border-right: none;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.customElementGroupBottomRight {
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0px;
}

.stripeCustomElementBorder {

}