// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  border-width: 0;

  &-drop {
    color: $body-color;
  }

  &-title {
    font-size: 16px;

    &-desc {
      color: $card-title-desc;
      margin-bottom: 24px;  
    }
  }

  &-body {
    padding: 1rem;
  }

  @media(max-width: 767px) {
    &-title {
      font-size: 18px;
    }
  }
}

