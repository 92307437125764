.issueHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &Wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.issueTitle {
  color: #020036;
  font-size: 18px;
  font-weight: 500;
}

.testNowBtn {
  background-color: #F0EFFF;
  color: #6C63FF;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 500;
  transition: all .3s linear;
  &:hover {
    color: #F0EFFF;
    background-color: #6C63FF;
    transition: all .3s linear;
  }
}

.completeBtn, .completeBtnSuccess {
  color: #fff;
  background-color: #4D46DD;
  border-radius: 15px;
  padding: 10px 20px;
}

.testNowBtn, .completeBtn, .completeBtnSuccess {
  &:disabled {
    opacity: 0.5;
  }
}

.completeBtnSuccess {
  background-color: #038226;
}

.issueCloseBtn {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: unset;
  height: 24px;
  font-size: 24px;
  color: #838383;
  background-color: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: unset;
    color: unset;
  }
}
