.pagination-btns {
  margin-left: 15px;
  .btn {
    padding: 0;
    background: transparent!important;
    border: none!important;
    border-radius: 3px;
    font-size: 40px;
    color: #4A548B!important;
    line-height: 29px;
    &.disabled{
      color: #4A548B!important;
    }
  }
  .next {
    margin-left: 7px;
  }
}
