.user-questions-box {
  .question-block {
    background-color: #ececf1;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 7px;
    min-width: 140px;
    max-width: 145px;
    border-radius: 5px;
    .question-text {
      font-weight: 300;
      font-size: 12px;
    }
    .question-answer {
      font-weight: 700;
      font-size: 16px;
      color: #626ed4;
    }
  }
}
