.color-scheme {
  position: relative;

  .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .5);
    z-index: 4;
  }

  &-item {
    .title {
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }
  }

  &-slider {
    position: relative;

    .color-left,
    .color-right {
      position: absolute;
      height: 8px;
      top: 13px;
      border-radius: 5px;
    }

    .color-left {
      width: 100%;
      left: 0;
    }

    .color-right {
      right: 0;
    }

    .MuiSlider-root {
      height: 8px;
      z-index: 1;
    }

    .MuiSlider-valueLabel {
      left: calc(50% - 16px);
    }

    .MuiSlider-rail {
      display: none;
      height: 8px;
      border-radius: 5px;
    }

    .MuiSlider-thumb {
      width: 16px;
      height: 16px;
      margin-top: -4px;
      background-color: #4F46E5;
    }

    .MuiSlider-track {
      height: 8px;
    }

    .MuiSlider-mark {
      display: none;
    }
  }
}