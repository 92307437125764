.internet-performance {

  &-label {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    color: #020036;
  }

  .item {
    display: flex;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #E3E1FF;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      min-width: 34px;
      height: 34px;
      margin-top: 3px;
      margin-right: 12px;
      border-radius: 100%;
      border: 2px solid #4F46E5;
      overflow: hidden;

      &.rotate-180 {
        transform: rotate(180deg);
      }
    }

    &-info {

    }

    &-title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: #020036;
      text-transform: uppercase;
      letter-spacing: .729px;
    }

    &-value {
      font-size: 20px;
      line-height: 24px;
      font-weight: 800;
      color: #000;

      &.good {
        color: #1CBE50;
      }

      &.medium {
        color: #FEBA00;
      }

      &.bad {
        color: #DC2D2D;
      }

      &.decent {
        color: #FEBA00;
      }

      span {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 600;
        color: #020036;
        letter-spacing: .729px;
      }
    }
  }

  .test-block {
    background: #74808E;
    padding: 10px;
    min-width: 140px;
    max-width: 145px;
    margin-right: 10px;
    margin-bottom: 7px;
    color: #FFFFFF;
    .label {
      font-weight: 600;
      font-size: 12px;
      opacity: 0.85;
    }
    .details {
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .speed-value {
      font-weight: bold;
      font-size: 30px;
      margin-right: 15px;
      color: #FFFFFF;
      &.good {
        color: #FFFFFF;
      }
      &.bad {
        color: #FF5844;
      }
    }
    .speed-indicator {
      font-weight: 300;
      font-size: 10px;
      opacity: 0.85;
      right: 10px;
    }
    .test-result {
      > div {
        width: 14px;
        height: 17px;
        border-radius: 4.2px;
        &.slow {
          background: #FF5844;
        }
      }
      > span {
        font-weight: 300;
        font-size: 12px;
        opacity: 0.85;
        margin-left: 10px;
      }
    }
    &.decent {
      border: 2px solid #FEBA00;
    }
    &.bad {
      border: 2px solid #FF5844;
    }

    @media(max-width: 647px) {
      width: calc(33.33333% - 10px);
      max-width: 33.33333%;
      min-width: unset;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media(max-width: 500px) {
      width: calc(50% - 5px);
      max-width: 50%;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 10px;
      }
    }
  }
}
