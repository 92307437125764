.card {
  width: 100%;
  height: 135px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10);
  padding: 16px;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  &.clickable {
    cursor: pointer;
  }
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #F8F8FF;
  margin-bottom: 12px;
}

.title {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  margin-bottom: 2px;
}

.value {
  font-size: 18px;
  color: #020036;
  font-weight: 500;
}
