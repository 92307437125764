.issueDescription {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  .issueDescriptionTop {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 2fr;

    .issueDescriptionReference {
      font-weight: 500;
      font-size: 14px;
      .issueDescriptionValue {
        color: #4F46E5;
      }
    }
  }

  .issueDescriptionTitle {
    font-size: 14px;
    margin-bottom: 10px;
    color: #5B626B;
  }

  .issueDescriptionValue {
    color: #020036;
  }
}
