.bottomBanner {
  background-color: #6B6DC9;
  min-height: 20px;
  height: max-content;
  padding: 5px 7px;
  width: 400px;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .text {
    color: #fff;
    font-size: 11px;
    overflow: hidden;
  }
  a {
    color: #fff;
    background-color: transparent;
    padding: 0;
    border: none;
    font-weight: 500;
    text-decoration: underline;
  }
}
