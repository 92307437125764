.gcredentials-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;

  .gcredentials-row {
    display: flex;
    align-items: center;
    gap: 10px;

    .gcredentials-title {
      font-weight: bold;
    }
    
    .gcredentials-value {
      padding: 5px;
      background-color: rgba(0, 0, 0, .05);
      border-radius: 4px;
    }
  }
}
