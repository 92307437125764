.subscriptionValues {
  height: 100%;

  & .valuesWrapper {
    max-width: 400px;
    background-color: #f5f5f5;
    padding: 16px;
  }

  & .title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    color: #000000;
  }

  & .testName {
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
  }
  & .progressBarWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    span {
      font-weight: bold;
    }
    & .progressBar {
      width: 100%;
    }
  }

  .card {
    background-color: #f3f3fb;
    color: #585a71;
    border: 1px solid #c0b9dd;
    border-radius: 3px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    display: flex;
    height: 100%;
    transition: transform .3s;

    &.cardActive {
      position: relative;
      background-color: #FFFFFF;
      border-color: transparent;
      box-shadow: 0 0 5px 5px rgba(117, 73, 255, .3);
      z-index: 1;
    }

    & .cardTitle {
      min-height: 44px;
      margin-bottom: 10px;
      color: #7549ff;
      font-size: 2vw;
      // font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      text-align: center;
    }
    & .cardText, .cardTextBold {
      color: #585a71;
      text-align: center;
      letter-spacing: .2px;
      align-self: center;
      margin-bottom: 15px;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 18px;
      line-height: 26px;
    }
    .cardText {
      min-height: 52px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .cardTextBottom {
      min-height: 52px;
    }
    & .cardTextBold {
      min-height: 26px;
      font-size: 17px;
      font-weight: bold;
    }
    & .textBold {
      font-weight: bold;
    }
    & .cardPrice {
      min-height: 35px;
      margin-bottom: 35px;
      font-size: 25px;
    }
    & .cardButtonWrapper {
      min-height: 44px;
      margin-bottom: 25px;
    }

    & .cardButton {
      margin-top: auto;
      border: 1px solid #7549ff;
      color: #4d45e0;
      white-space: nowrap;
      background-color: #fff;
      border-radius: 10px;
      padding: 8px 24px;
      font-size: 18px;
      line-height: 26px;
      transition: .3s all ease-in-out;
      &:hover {
        background-color: #4d45e0;
        color: #fff;
        transition: .3s all ease-in-out;
      }
    }

    & .cardBottomPart {
      text-align: center;
    }

    & .cardLink {
      margin-top: 65px;
      margin-bottom: 51px;
    }
  }

  @media(min-width: 1920px) {
    .card {
      & .cardTitle {
        font-size: 36px;
      }

      & .cardText {
        font-size: 18px;
      }
    }
  }

  @media(max-width: 1440px) {
    .card {

      & .cardText {
        font-size: 1.2vw;
      }
    }
  }

  @media(max-width: 767px) {
    .card {
      & .cardTitle {
        font-size: 5vw;
      }

      & .cardText {
        font-size: 4vw;
      }
    }
  }
}

.plansTitle {
  margin-top: 6px;
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  color: #000000;
}

.plansList {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 14px;
}

.progressBarContainer {
  position: relative;
  background: rgb(224, 224, 222);
  border-radius: 50px;
  width: 100%;
  overflow: hidden;
}

// .progressBarCompleted {
//   position: relative;
// }

.progressBarLabel {
  position: absolute;
  top: 1.5px;
  right: 10px;
  color: #000;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

@media(max-width: 767px) {
  .top {
    flex-direction: column;
  }
}

@media screen and (max-width: 624px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
}
