.ai-response {
  position: relative;
  z-index: 1;

  &-title {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    color: #090A0B;
    text-transform: capitalize;
  }
}
