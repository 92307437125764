.overview {
  padding: 0 80px;
  max-width: 1800px;

  > div:first-child {
    width: 100%;
  }

  .overview-block-title {
    margin-top: 15px;
  }

  &-second-block {
    border-bottom: 1px solid #DBDBDB;
  }

  &-performance {
    padding: 40px 0;

    h4 {
      font-size: 22px;
      color: #090A0B;
      margin-bottom: 8px;
    }
  }

  &_bg {
    background-color: #fff;
  }

  &-metric {
    display: flex;
    margin-left: 64px;
    flex-direction: column;
    color: #090A0B;

    > div {
      display: flex;
    }

    h6 {
      margin-right: 40px;
    }

    &-one {
      > div:nth-child(2n) {
        color: #038226;
      }
    }

    &-two {
      > div:nth-child(2n) {
        color: #DC2D2D;
      }
    }

    &-wrapper {
      display: flex;
      padding: 40px 0;
    }
  }

  &-issues {
    padding: 60px 12px;

    &-title-wrapper {
      display: flex;
      justify-content: space-between;
    }

    h6 {
      color: #090A0B;
      margin-bottom: 4px;
    }

    button {
      background-color: #4F46E5;
      width: 178px;
      height: 42px;
      border-radius: 8px;
      border: none;
      outline: none;
      color: #fff;
    }
  }

  &-page-title {
    border-bottom: 1px solid #DBDBDB;
    padding: 40px 0;

    h4 {
      font-size: 32px;
      color: #090A0B;
    }

    span {
      color: #656565;
    }
  }

  &-block {
    position: relative;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, .5);
      z-index: 1;
    }

    &-title {
      margin-bottom: 10px;

      h3, h5 {
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: #020036;
        text-transform: capitalize;
      }

      h5 {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &-subtitle {
      margin-bottom: 16px;
      font-size: 16px;
    }

    &-link {
      margin: 24px 0;
      text-align: center;

      a {
        min-width: 160px;
      }
    }

    &_center {
      .overview-card {
        height: auto;
      }
    }
  }

  &-card {
    position: relative;
    height: 100%;
    margin-bottom: 0;
    padding: 20px 42px;
    box-shadow: 0px 0px 36px 6px rgba(38, 38, 54, 0.04);
    border-radius: 6px;
    overflow: hidden;

    &-body {
      padding: 0;
    }

    &-line {
      position: absolute;
      width: 100%;
      height: 6px;
      top: 0;
      left: 0;
      background: linear-gradient(90.23deg, #3C37BE 11.17%, #625EC9 50.42%, #E99ACF 92.19%);
    }

    &-title {
      margin-bottom: 28px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #020036;
      text-align: center;
    }

    &-block {
      display: flex;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 15px;
      font-size: 30px;
      border-radius: 8px;

      &_success {
        color: #67E7B0;
        background-color: rgba(103, 231, 176, .2);
      }

      &_danger {
        color: #F05528;
        background-color: rgba(240, 85, 40, .2);
      }

      &_dark {
        color: #F05528;
        background-color: rgba(240, 85, 40, .2);
      }

      &_white {
        color: #FFF;
        background-color: rgba(255, 255, 255, .3);
      }

      &_purple {
        color: #726DF9;
        background-color: rgba(114, 109, 249, .3);
      }
      &_decent {
        color: #FACC52;
        background-color: rgba(250, 204, 82, .2);
      }
    }

    &-link {
      display: flex;
      margin-bottom: 2px;
      color: #8984B0;

      .icon {
        margin-left: 8px;
      }
    }

    &-value {
      display: block;
      font-size: 24px;
      font-weight: 500;
      line-height: 26px;
      color: #020036;
    }

    &_secondary {
      color: #FFF;

      .overview-card {
        &-link {
          color: #FFF;
        }

        &-value {
          color: #FFF;
        }
      }
    }

    &_dark {
      background-color: #020036;
    }

    &_purple {
      background-color: #625EC9;
    }

    &_pink {
      background-color: #E99ACF;
    }

    &-rate {
      display: flex;
      align-items: center;

      .icon {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        font-weight: 500;
        line-height: 18px;
      }

      &.down {
        color: #F05528;
      }

      &.up {
        color: #67E7B0;
      }
    }

    .col-separate {
      position: relative;

      &:last-child {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: #E5E5EE;
        }
      }
    }

    &-app {
      padding: 24px;

      .overview-card {
        &-title {
          margin-bottom: 0;
          font-size: 22px;
          line-height: 34px;
          text-align: left;
          text-transform: capitalize;
        }

        &-link {
          display: inline-block;
          margin-bottom: 22px;
          font-size: 12px;
          line-height: 18px;
          text-decoration: underline !important;
        }
      }

      .list-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &-name {
          padding-right: 10px;
          line-height: 21px;
          color: #8984B0;
        }

        &-value {
          font-size: 18px;
          line-height: 21px;
          font-weight: 500;

          span {
            font-size: 12px;
          }

          &.good {
            color: #00c785;
          }
          &.descent {
            color: #feba00;
          }
          &.poor {
            color: #fd6c5a;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-device {
      height: 100%;

      .overview-card {

        &-title {
          margin-bottom: 24px;
        }
      }

      .bar-chart {
        display: flex;
        height: 8px;
        margin-bottom: 30px;
        border-radius: 8px;
        overflow: hidden;

        .child0 {
          background-color: #67E7B0;
        }
        .child1 {
          background-color: #F05528;
        }
        .child2 {
          background-color: #726DF9;
        }
        .child3 {
          background-color: #FACC52;
        }
        .child4 {
          background-color: #5188F2;
        }
      }

      .list {
        &-item-name {
          position: relative;
          padding-left: 16px;
          text-transform: capitalize;

          &.child0 {
            &:before {
              background-color: #67E7B0;
            }
          }
          &.child1 {
            &:before {
              background-color: #F05528;
            }
          }
          &.child2 {
            &:before {
              background-color: #726DF9;
            }
          }
          &.child3 {
            &:before {
              background-color: #FACC52;
            }
          }
          &.child4 {
            &:before {
              background-color: #5188F2;
            }
          }

          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 6px;
            left: 0;
          }

        }

        &-item-value {
          font-size: 16px;
          font-weight: 500;
          color: #262636;
        }
      }
    }
  }

  &-stats {

    &-row {

      .col {
        margin-bottom: 15px;
      }

      .card {
        height: 100%;
        margin-bottom: 0;

        &-body {
          display: flex;
          flex-direction: column;
          padding: .8rem;

          p {
            margin-top: auto;
          }
        }

        &-title {
          margin-bottom: 0.7rem;
        }
      }
    }

    &-card {
      border-radius: 12px;
      &.bg-danger {
        background-color: #ff7878!important;
      }
      &.bg-gray {
        background-color: #f7f7f7;
      }
      .card-title {
        font-size: 1rem;
        font-weight: normal;
      }
      .card-text{
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: #262636;
        .text-good {
          color: #0CC778;
        }
        .text-decent {
          color: #D8B655;
        }
        .text-poor {
          color: #E00707;
        }
        .text-custom {
          font-size: 15px;
          color: #959595;
        }
      }
      b {
        font-weight: bold;
      }

      &.custom {
        a {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }

    @media(max-width: 767px) {

      &-row {
        justify-content: center;
      }

      &-card {
        .card-title {
          font-size: 0.9rem;
        }
        .card-text {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }
  }

  @media(max-width: 1240px) {

    &-card {
      padding-right: 20px;
      padding-left: 20px;

      &-icon {
        width: 40px;
        min-width: 40px;
        height: 40px;
        padding: 10px;
      }

      &-link {
        line-height: 15px;
      }

      &-value {
        font-size: 20px;
      }
    }
  }

  @media(max-width: 575px) {

    &-card {
      .col-separate {
        &:before {
          display: none;
        }
      }
    }
  }
}

