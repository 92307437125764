.issuesSettings {

  :global(.spinner-wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global(.button-edit) {
    margin-left: 2px;
    padding: 3px 5px;
  }

  :global(.children-wrapper) {
    display: inline-block;
  }
}