// button,a {
//   outline: none !important;
// }

.btn {
    padding: 12px 22px;
    font-family: Inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #4F46E5;
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    border: none;
    box-shadow: none;
    letter-spacing: 0.1px;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    // &:hover {
    //   color: #A256ED;
    //   background-color: #FAF5FF;
    // }

    // &:focus {
    //   color: #4F46E5;
    //   background-color: #FAF5FF;
    // }

    // &:active {
    //   color: #6F23BA;
    //   background-color: #FAF5FF;
    // }

    // &:disabled {
    //   color: rgb(28, 27, 31, .38);
    // }
  
  // &-default {
  //   color: #FFFFFF;
  //   background-color: #4F46E5;

  //   &:hover {
  //     color: #FFFFFF;
  //     background-color: #A256ED;
  //     box-shadow: 0px 0px 1px rgba(0, 0, 0, .25), 0px 1px 7px 1px rgba(0, 0, 0, .12);
  //   }

  //   &:disabled {
  //     color: rgba(28, 27, 31, .38);
  //     background-color: rgba(28, 27, 31, 0.12);

  //     &:hover {
  //       box-shadow: none;
  //     }
  //   }

  //   &:active {
  //     color: #FFFFFF;
  //     background-color: #6F23BA;
  //   }

  //   &-outlined {
  //     outline: 1px solid rgba(9, 10, 11, 0.2);

  //     &:hover {
  //       color: #A256ED;
  //       background-color: #FAF5FF;
  //     }

  //     &:disabled {
  //       color: rgba(9, 10, 11, .38);

  //       &:hover {
  //         background-color: transparent;
  //       }
  //     }

  //     &:focus {
  //       color: #4F46E5;
  //       background-color: #FAF5FF;
  //       outline: 1px solid rgba(9, 10, 11, 0.2) !important;
  //     }

  //     &:active {
  //       color: #6F23BA;
  //       background-color: #FAF5FF;
  //     }
  //   }
  // }

  &-text {
    
  }

  &-icon {
    display: flex;
    align-items: center;

    // &:hover {
    //   color: #A256ED;
    //   background-color: #FAF5FF;
    // }

    // &:focus {
    //   color: #4F46E5;
    //   background-color: #FAF5FF;
    // }

    // &:active {
    //   color: #6F23BA;
    //   background-color: #FAF5FF;
    // }

    // &:disabled {
    //   color: rgb(28, 27, 31, .38);
    // }

    .icon {
      margin-right: 8px;
    }

    &-right {
      .icon {
        margin-left: 8px;
      }
    }
  }

  // &-elevated {
  //   color: #4F46E5;
  //   background-color: #FAF5FF;
  //   box-shadow: 0px 0px 1px rgba(0, 0, 0, .25), 0px 1px 7px 1px rgba(0, 0, 0, .12);

  //   &:hover {
  //     color: #A256ED;
  //   }

  //   &:active {
  //     color: #6F23BA;
  //   }

  //   &:disabled {
  //     color: rgba(9, 10, 11, .38);
  //     background-color: rgba(9, 10, 11, .1);
  //     box-shadow: 0;
  //   }
  // }
  
  &-primary {
    color: #FFFFFF;
    background-color: #4A548B;
    &:hover, &:active {
      background-color: #5965a7;
    }
    &.disabled,
    &:disabled {
      color: #fff;
      background-color: #626ed4;
      border-color: #626ed4;
    }
  }

  &-secondary {
    background-color: #ffffff;
    border: 1px solid #4A548B;
    color: #4A548B;
    &:hover, &:active {
      background-color: #f1f1f1;
    }
  }

  &-rounded {
    border-radius: 30px;
  }

  &-dark{
    color: $gray-200 !important;
  }

  &-outline-dark {
    &:hover{
      color: $gray-200 !important;
    }
  }
}

//Custom Button Library
.button {
  display: inline-block;
  padding: 12px 22px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #4F46E5;
  background-color: transparent;
  border-radius: 8px;
  outline: none;
  border: none;
  box-shadow: none;
  text-align: center;
  letter-spacing: 0.1px;

  &:focus {
    box-shadow: none;
  }

  &-default {
    color: #FFFFFF;
    background-color: #4F46E5;

    &:hover {
      color: #FFFFFF;
      background-color: #A256ED;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, .25), 0px 1px 7px 1px rgba(0, 0, 0, .12);
    }

    &.active,
    &:active {
      color: #FFFFFF;
      background-color: #6F23BA;
    }

    &:disabled {
      color: rgba(28, 27, 31, .38);
      background-color: rgba(28, 27, 31, 0.12);

      &:hover {
        box-shadow: none;
      }
    }

    &-outlined {
      outline: 1px solid rgba(9, 10, 11, 0.2);

      &:hover {
        color: #A256ED;
        background-color: #FAF5FF;
      }

      &:focus {
        color: #4F46E5;
        background-color: #FAF5FF;
        outline: 1px solid rgba(9, 10, 11, 0.2) !important;
      }

      &:active {
        color: #6F23BA;
        background-color: #FAF5FF;
      }

      &:disabled {
        color: rgba(9, 10, 11, .38);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &-text {
    
  }

  &-icon {
    display: flex;
    align-items: center;

    // &:hover {
    //   color: #A256ED;
    //   background-color: #FAF5FF;
    // }

    // &:focus {
    //   color: #4F46E5;
    //   background-color: #FAF5FF;
    // }

    // &:active {
    //   color: #6F23BA;
    //   background-color: #FAF5FF;
    // }

    // &:disabled {
    //   color: rgb(28, 27, 31, .38);
    // }

    .icon {
      margin-right: 8px;
    }

    &-right {
      .icon {
        margin-left: 8px;
      }
    }
  }

  &-elevated {
    color: #4F46E5;
    background-color: #FAF5FF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, .25), 0px 1px 7px 1px rgba(0, 0, 0, .12);

    &:hover {
      color: #A256ED;
    }

    &:active {
      color: #6F23BA;
    }

    &:disabled {
      color: rgba(9, 10, 11, .38);
      background-color: rgba(9, 10, 11, .1);
      box-shadow: 0;
    }
  }

  &-size {
    &-small {
      padding: 8px 16px;
    }
  }

  &-refresh {
    width: 30px;
    height: 30px;
    min-height: 30px;
    padding: 0;
    font-size: 20px;
    border-radius: 50%;
  }

  .icon {
    margin-right: 8px;

    &_right {
      margin-left: 8px;
    }
  }
}