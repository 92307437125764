.chartWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .labels {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 12px;
    .label {
      font-size: 12px;
      color: #020036;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      .round {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  .chart {
    max-width: 350px;
    width: 100%;
    height: 202px;
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
  }
}
