.card {
  //max-width: 240px; NOTE: Temporary hidden
  width: 100%;
  height: 80px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10);
  padding: 16px;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  cursor: pointer;
}

.title {
  margin-bottom: 6px;
  display: flex;
  > h4 {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    margin-bottom: 2px;
  }
  .percent {
    margin-left: 8px;
    padding: 0 4px;
    border-radius: 8px;
    display: grid;
    place-items: center;
    background: red;
    color: #fff;
  }
}

.value {
  font-size: 18px;
  color: #020036;
  font-weight: 500;
}
