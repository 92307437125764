
.session-info {
  // flex: 1 1 auto;
  .custom-select {    
    .WithStyles\(ForwardRef\(InputBase\)\)-input-368:focus {
      -webkit-appearance: none;
    }
    border: none;
    padding: 0;
  }
  .sessions-nr {
    font-weight: 300;
    font-size: 12px;
    padding: 5px 0 0 5px;
  }
  .item {
    margin-right: 40px;
    margin-bottom: 10px;
    > span:last-child {
      font-weight: 300;
      font-size: 12px;
      opacity: 0.85;
    }
  }
}

.padding-left-30 {
  padding-left: 30px;
}
