.map-view-cards {
  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
    padding: 16px;
    color: #4F46E5;
    text-align: center;

    &-value {
      color: #4F46E5;
    }

    &-label {
      color: #4F46E5;
    }
  }
};
