.issues-resolved-stats {
  width: 100%;
  height: 300px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10);
  padding: 16px 16px 0 16px;
  border-radius: 8px;
  background-color: #fff;
  .title {
    color: #020036;
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: bold;
    text-align: left;
  }
}
