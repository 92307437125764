.aiServiceMetrics {
  margin-bottom: 40px;
  .content {
    display: flex;
    gap: 24px;
    .chart {
      //max-width: 450px; NOTE: Temporary hidden
      width: 100%;
    }
    .cards {
      //max-width: 574px; NOTE: Temporary hidden
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      gap: 24px;
    }
  }
  .spinnerWrapper {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .chart {
      margin-bottom: 24px;
    }
  }
}
