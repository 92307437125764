
//
// Google map
//

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: $gray-100;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: $white;
  font-size: 16px;
  line-height: 40px;
  background: $primary;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
  &.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid $primary;
  }
  &.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid $primary;
  }
  
}

.gm-control-active>img:nth-child(1) {
  display: block;
}

.gm-control-active:focus>img:nth-child(1),
.gm-control-active:hover>img:nth-child(1),
.gm-control-active:active>img:nth-child(1),
.gm-control-active:disabled>img:nth-child(1) {
  display: none;
}

.gm-control-active:focus>img:nth-child(2),
.gm-control-active:hover>img:nth-child(2) {
  display: block;
}

.gm-control-active:active>img:nth-child(3) {
  display: block;
}

.gm-control-active:disabled>img:nth-child(4) {
  display: block;
}

.gm-control-active>img {
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
  box-sizing: content-box;
}