.averages {
  --block-shadow: 0px 0px 32px rgba(74, 45, 197, 0.07);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr .6802fr;
  grid-gap: 10px;
  margin-bottom: 1.5rem;
}

.block {
  display: flex;
  align-items: center;
  color: var(--bs-black)
}

.label {
  flex: 1;
  padding: 10px;
  font-size: 12px;
  background-color: var(--bs-white);
  border-radius: 16px;
  text-align: center;
  box-shadow: var(--block-shadow);
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.icon img {
  display: block;
  max-width: 100%;
  height: 100%;
}

.name {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}

.name:before{
  content: '';
  display: block;
  width: 8px;
  min-width: 8px;;
  height: 8px;
  margin-top: 4px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #979CA3;
}

.valueBlock {
  margin-left: 10px;
  padding: 8px;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--bs-white);
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: var(--block-shadow);
  text-align: center;
  cursor: pointer;
  transition: outline .3s;
}

.valueBlock:hover,
.valueBlock.active {
  border-color: #D0D5DD;
}

.value {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  word-break: break-all;
}

.value span {
  font-size: 16px;
  line-height: 24px;
}

@media(max-width: 991px) {
  .averages {
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width: 767px) {
  .averages {
    grid-template-columns: 1fr;
  }

  .block {
    flex-direction: column;
  }

  .label {
    width: 100%;
    max-width: 190px;
    margin-bottom: 10px;
  }

  .valueBlock {
    margin-left: 0;
  }
}
