.table-data-export {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;

  .title {
    margin-right: 5px;
  }

  .range-options,
  .choose-file{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 16px;

    label {
      color: #333547;
      font-size: 16px;
      background-color: #f2f2f5;
      min-width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color .3s, color .3s;

      &.checked {
        color: #fff;
        background-color: #4F46E5;
      }
    }
  }

  @media(max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;

    .range-options,
    .choose-file {
      margin-right: 0;
      margin-bottom: 16px;
    }

    .btn {
      &-default {
        margin-left: auto;
      }
    }
  }
}
