.wrapper {
  width: 400px;
  height: 100%;
  .headerBtn {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 32px;
    gap: 8px;
    cursor: pointer;
    background-color: #fff;

    svg {
      transform: rotate(180deg);
      transition: transform 0.2s;
    }

    span {
      font-size: 24px;
      color: #020036;
      font-weight: 500;
    }
    &:hover {
      svg {
        transition: transform 0.2s;
        transform: rotate(0deg);
      }
    }
  }
  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 0 32px;

    span {
      font-size: 16px;
      color: #5B626B;
    }

    select {
      width: 135px;
    }
  }
  .issues {
    width: inherit;
    margin-top: 22px;
    position: fixed;
    height: calc(100% - 162px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px 32px 32px;
    overflow-y: scroll;
  }
  .noIssuesMsg {
    text-align: center;
    font-size: 16px;
  }
}


@media screen and (max-width: 400px) {
  .wrapper{
    width: 320px;
  }
}
