@mixin textStyle {
  font-weight: 700;
  color: #5b626b;
}
@mixin marginBottom {
  margin-bottom: 40px;
}

.issueSettingsDetail {
  :global(.spinner-wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @include marginBottom;
}

.description {
  @include textStyle;
}

.formGroupNoInput {
  @include textStyle;
  @include marginBottom;
  color: #5b626b;
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    margin-left: 10px;
    color: #000;
    text-transform: capitalize;
  }
}
