$dark-blue-color: #020036;
$light-blue-color: #4F46E5;

:local .carosel {
  text-align: center;

  :global(.slick-dots) {
    li {
      button {
        margin-right: 0;
  
        &:before {
          font-size: 8px;
        }

        &:hover:before {
          color: $light-blue-color !important;
        }
      }
      margin-right: 5px;
      margin-left: 0;

      :last-child {
        margin-right: 0;
      }
    }
  }
  :global(.slick-active) {
    button:before {
      color: $light-blue-color !important;
      opacity: 1 !important;
    }
  }
}

.heading {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: $dark-blue-color;
  ;
}

.subheading {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $dark-blue-color;
}

.image {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}