.modal {
  &:global(.fullHeight) {
    height: calc(100% - 56px);
    
    :global(.modal-content) {
      height: 100%;
    }
  }
}

.modalHeader {
  &:global(.modal-header) {
    padding: 1rem;
  }
}

.modalBody {
  :global(.form-actions) {
    display: flex;
    justify-content: flex-end;
  }
}

.modalFooter {

}