.content {
  display: flex;
  gap: 24px;
}

.spinnerWrapper {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}
