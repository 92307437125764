// 
// _table.scss
// 

.table {
  font-size: 15px;
  background-color: #fff;
  color: #090A0B;

  th {
      font-weight: $font-weight-bold;   
  }

  th, td {
    padding: .5rem;
  }

  thead {    

    th {
      border-top: none;
      border-bottom: 1px solid #DBDBDB;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      color: #656565;
      letter-spacing: .5px;
      text-transform: uppercase;

      .icon {
        margin-left: 8px;
        font-size: 14px;
        
        .down {
          color: #B8B5FF;
        }

        .up {
          color: #C4C4C4;
        }
      }
    }
  }

  tbody {
    font-size: 12px;

    td {
      border-top-color: transparent;
      border-bottom: transparent;
    }

    a {
      color: #090A0B;
    }

    tr {
      &:last-child {
        td {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .actions {
    .button {
      &-default {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }

  .form-group {
    &_checkbox {
      margin-bottom: 0;

      label {
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}