.nfo-support-header {
  text-align: center;
  position: relative;

  .navbar {
    .navbar-toggler {
      border: none;
    }

    .navbar-nav {
      .nav-item {
        float: left;
        color: #000000;
        font-size: 12px;
        font-style: normal;
        text-align: left;
        margin-right: 15px;
        letter-spacing: 1px;
        font-weight: normal;
        transition: all 0.3s linear;
        margin-bottom: 0;

        .nav-link {
          color: #000000;

          &:hover {
            color: rgba(0, 0, 0, 0.5);
          }

          &.styled-button {
            border-color: #33CC66;
            background-color: #33CC66;
            border-radius: 15px;
            display: inline-block;
            padding: 6px 20px;
            color: #ffffff;
          }
        }
      }
    }

    @media (max-width: 1199px) {
      .navbar-collapse {
        .navbar-nav {
          padding: 0;
          float: none;
          margin: 0;

          .nav-item {
            margin: 0;
            width: 100%;
            text-align: left;
            padding: 5px 0;
            border-bottom: 1px solid #ebebeb;
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }


  }
}

.nfo-support-footer {
  background: #242424;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .container {
    .row{
      width: 100%;
      padding: 56px 0 66px 30px;
      margin: 0 auto;

      p {
        color: #D3D5D1;
        margin: 10px 0;
        font-weight: 300;
        &.socialLinks a {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
        a {
          display: block;
          margin: 10px 0;
        }
        strong {
          font-weight: bold;
        }
      }
      a {
        text-decoration: none;
        color: #D3D5D1;
        &:hover {
          color: #61CE70;
        }
      }
    }
  }
}
