.avatarContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  min-width: 54px;
  height: 54px;
  margin-right: 20px;
  font-size: 21px;
  color: #4F46E5;
  border-radius: 100%;

  .spinnerWrapper {
    background: initial!important;
  }

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .statusIndicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

    &.deactivated {
      border-color: red;
      color: red;
    }

    &.online {
      border-color: green;
      color: green;
      animation: pulse 2s infinite;
    }

    &.offline {
      color: #36454F;
      border-color: #929292;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
}
