.modalWrapper {
  max-width: 600px;
  width: 100%;
  position: relative;
  top: 10%;
  margin: 0 auto;
  height: auto;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
    .issueCloseBtn {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      min-width: unset;
      height: 24px;
      font-size: 24px;
      color: #838383;
      background-color: transparent;
      border: none;
      text-align: center;
      cursor: pointer;
      z-index: 1;
      &:hover {
        background-color: unset;
        color: unset;
      }
    }
  }

  .control {
    display: flex;
    justify-content: space-around;
    button {
      background-color: #4D46DD;
      color: #fff;
      &:hover {
        background-color: #28a745;
      }
    }
  }

  .textArea {
    overflow: hidden;
  }
}
