.chatWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chatTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #c4c8d4;
  .avatar {
    max-width: 25px;
    height: 25px;
    padding: 4px;
    width: 100%;
  }
  p {
    font-weight: 600;
  }
}

.warrningMsg {
  text-align: center;
  color: #fff;
  background-color: tomato;
  padding: 3px;
}

.chatContainer {
  width: 100%;
  padding: 10px;
  margin: 0 auto 20px auto;
  border-radius: 5px;
  background-color: #fff;
  &[aria-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.chatWindow {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 6px rgba(6, 41, 142, 0.2);
  display: flex;
  flex-direction: column;
}

.chatMessage {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 5px;
  max-width: 93%;
  animation: FadeIn 1s;

  &.visible {
    opacity: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.userMessage {
  align-self: flex-end;
  text-align: right;
  .messageText {
    background-color: #eae9ff;
    border-radius: 10px 10px 0 10px;
    color: #4d46dd;
    border: 1px solid #4d46dd;
    padding: 15px;
  }
}

.aiMessage {
  align-self: flex-start;
  align-items: flex-end;
  display: flex;
  gap: 10px;
}

.messageText {
  background-color: #4d46dd;
  color: #fff;
  border-radius: 10px 10px 10px 0;
  padding: 15px;
  white-space: pre-line;
}

.avatar {
  max-width: 40px;
  width: 100%;
  height: 40px;
  padding: 7px;
  border-radius: 50%;
  background-color: #eae9ff;
}

.chatInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chatInput input {
  flex: 1;
  padding: 10px;
  min-height: 50px;
  border: 1px solid #c1ccf5;
  border-radius: 5px;
  &::placeholder {
    vertical-align: top;
  }
}

.chatInput button {
  padding: 10px;
  border: none;
  background-color: #4d46dd;
  align-self: flex-end;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  &:disabled {
    opacity: 0.5;
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aiLoader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}
