.experience-summary {

  &-label {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    color: #020036;
  }

  &-block {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      margin-bottom: 8px;
      font-size: 14px;
      color: #5B626B;
    }
  }

  .status-elem {
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
  }
  .status-label {
    font-weight: 300;
    font-size: 12px;
    opacity: 0.85;
  }
  .good {
    color: #63AE63;
  }
  .decent {
    color: #FEBA00;
  }
  .bad {
    color: #FF5844;
  }

  .wifi-status {
    &-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      background-color: #FEBA00;
      text-align: center;

      &.good {
        background-color: #63AE63;
      }
    }
  }
}
