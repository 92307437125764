@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Questrial&display=swap');
@import url('https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css');
@import '../../components/MapViewCards/MapViewCards.scss';
@import './ColorScheme.scss';

$neonBlue: #4F46E5;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5b626b;
  text-align: left;
  background-color: #FFFFFF;
}

p {
  margin: 0;
}

b {
  font-weight: 700;
}

.header {
  &-invite,
  &-profile {

    &> .btn {
      padding: 12px 24px;
      transition: background-color .3s, color .3s;
      border: 1px solid rgba(9, 10, 11, 0.2);
      border-radius: 8px;
      width: 83px;
      height: 42px;
      background-color: #fff;

      &:hover,
      &:active {
        color: #FFFFFF;
        background-color: rgba(60, 55, 190, .7);
      }
    }

    &.show {

      &> .btn {
        border-color: $neonBlue;
      }
    }
  }
}

.trial {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: $trial-height;
  // top: -40px;
  top: 0;
  left: 0;
  padding-right: 64px;
  padding-left: 64px;
  color: #FFF;
  background: radial-gradient(50% 107887.6% at 50% 49.99%, #3431B6 0%, #8B42D5 100%);
  text-transform: uppercase;
  transition: top .2s;
  z-index: 1002;

  &.show {
    top: 0;
  }

  &-days {
    margin-right: 5px;
    font-weight: 700;
  }

  .btn-close {
    position: absolute;
    top: 50%;
    right: 15px;
    padding: 4px 10px;
    color: #FFF;
    transform: translateY(-50%);
  }

  .btn-link {
    &_custom {
      padding: 0;
      border: none;
    }
  }

  &-link {
    margin-right: 5px;
    margin-left: 5px;
    color: #FFF;
    font-weight: 700;
    text-decoration: underline !important;

    &:hover {
      color: #FFF;
    }
  }
}

.test-style {
  background: red !important;
}

.justify-between {
  justify-content: space-between;
}

.back-button {
  padding: 0 25px;
  .prev{
    i {
      margin-right: 10px;
    }
  }
}

.d-push-right {
  margin-left: auto;
}

.mapboxgl-canvas {
  min-width: 100%;
}

.card {

  &-top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &_custom {
      .card-top-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 0;
        font-size: 20px;
        color: #000000;
        font-weight: 600;

        h4 {
          margin-right: 10px;
        }
      }
    }

    &-title {
      margin-right: 10px;
    }

    .card-title {
      margin-bottom: 0;
    }

    @media(max-width: 768px) {
      justify-content: center;
    }

    @media(max-width: 599px) {
      &_custom {
        .card-top-title {
          justify-content: center;
        }
      }
    }
  }
}

.card-title {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  & + button {
    margin-left: 10px;
  }

  &-date {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  @media(max-width: 767px) {
    font-size: 16px;
  }

  @media(max-width: 599px) {
    &-date {
      display: block;
      margin-left: 0;
      font-size: 14px;
    }
  }
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pr-30 {
  padding-right: 30px;
}

.map-background {
  background-color: #ebebec;
}

.map-wrapper {
  border-radius: 16px;
  overflow: hidden;
}

.text-capitalize {
  text-transform: capitalize;
}

/* Modal */
.modal-body {

  .form-group {
    position: relative;

    .error {
        position: absolute;
        bottom: -15px;
        left: 0;
        font-size: 12px;
        line-height: 14px;
    }

  }

  .tab-content {
    padding-top: 16px;
  }

  .custom-file-label {
    border-color: #4A548B;

    &:after {
      color: #fff;
      background-color: #4A548B;
    }
  }
}

.modal {

  &-content {
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
  }

  &-header {
    padding: 0 0 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #090A0B;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-color: #DBDBDB;
    text-transform: capitalize;
  }

  // &-body {
  //   padding: 40px 0 0;
  // }

  // &-footer {
  //   padding: 40px 0 0 0;

  //   .btn {
  //     margin: 0 0 0 8px;
  //   }
  // }

  &-text {
    margin-bottom: 40px;
    font-family: 'Intr', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #808080;
  }

  .link {
    font-weight: 700;
    text-decoration: underline !important;
  }

  & .custom {
    max-width: 716px;

    .modal {
      &-content {
        padding: 20px;
        background-color: #FAFAFA;
        border: 1px solid rgba(9, 10, 11, 0.2);
        border-radius: 16px;
        box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        .modal-header {
          h5 {
            text-transform: none;
          }
        }
        .modal-footer {
          padding-bottom: 0;
        }
      }
    }

    .form {
      &-group {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .nav {
    &-tabs {
      border-bottom: 0;

      .nav-item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      .nav-link {
        margin-bottom: 0;
        font-weight: 500;
        color: $neonBlue;
        background-color: transparent;
        border: none;
        border-radius: 8px;

        &:hover {
          color: #FFFFFF;
          background-color: $neonBlue;
        }

        &.active {
          color: #FFFFFF;
          background-color: $neonBlue;
        }
      }
    }
  }

  .tab-content {
    padding-top: 40px;
  }

  &-onboarding {
    max-width: 1170px;

    .modal {

      &-header {
        padding: 40px 40px 44px 40px;
        border-bottom-color: transparent;

        .close {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 30px;
          height: 30px;
          top: 32px;
          right: 32px;
          opacity: 1;

          span {
            font-size: 28px;
            color: #000000;
          }
        }
      }

      &-body {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &-logo {
        display: block;
        max-width: 160px;
      }

      &-content {
        min-height: 930px;
      }
    }

  }
}

.button-default {
  cursor: pointer;
}

/* Onboarding */
.onboarding {
  display: flex;
  flex-direction: column;
  flex: 1;

  .invitation {
    margin-bottom: 40px;
    text-align: center;

    &-img {
      max-width: 94px;
      margin: 0 auto 32px;
    }

    &-actions {
      margin-bottom: 40px;
    }
  }

  &-form {
    max-width: 470px;
    margin: 0 auto 32px;

    label {
      margin-bottom: 6px;
      font-size: 12px;
      line-height: 18px;
      color: #3C37BE;
    }

    .form-control {
      height: 44px;
      border-color: #BCBCBC;
      border-radius: 8px;
      color: #000000;

      &:focus {
        border-color: #3C37BE;
      }
    }

    .form-actions {
      text-align: center !important;

      .btn {
        min-width: 170px;
        background-color: #3C37BE;
      }
    }
  }

  .btn {
    // font-weight: 500;
    // line-height: 32px;
    // color: #ffffff;
    // background-color: #3C37BE;
    // border-radius: 8px;
    // text-transform: capitalize;

    &-link {
      text-decoration: none;
    }
  }

  &-block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-stepper {
    margin-bottom: 40px;
    text-align: center;

    .MuiSvgIcon-root {
      width: 34px;
      height: 34px;
      font-size: 14px;
    }

    .MuiStepper-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiStepIcon-root {
      color: #D1D0F6;
    }

    .MuiStepLabel-root {
      position: relative;
      z-index: 2;
    }

    .MuiStepConnector-alternativeLabel {
      top: 16px;
      left: -50%;
      right: 50%;
    }

    .MuiStepConnector-lineHorizontal {
      border-top-width: 3px;
    }

    .MuiStepConnector-line {
      border-color: #D1D0F6;
    }

    .MuiStepLabel-label {
      margin-top: 12px;
      color: #D1D0F6;
      text-transform: capitalize;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
      color: #625EC9;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
      color: #625EC9;
    }

    .MuiStepLabel-label.MuiStepLabel-active {
      font-weight: 400;
      color: #625EC9;
    }

    .MuiStepLabel-label.MuiStepLabel-completed {
      font-weight: 400;
      color: #625EC9;
    }

    .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
      background-color: #fff;
      border-radius: 100%;
    }

    .spinner-border {
      color: #625EC9;
    }
  }

  .spinner {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &-step {
    max-width: 970px;
    margin-right: auto;
    margin-left: auto;

    .title {
      margin-bottom: 20px;
      font-size: 34px;
      font-weight: 500;
      line-height: 50px;
      color: #625EC9;
      text-align: center;
    }

    .subtitle {
      max-width: 658px;
      margin: 0 auto 40px;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      text-align: center;
    }

    .video {
      max-width: 770px;
      margin-right: auto;
      margin-left: auto;

      iframe {
        width: 100%;
        height: 418px;

        .ytp-chrome-top {
          display: none;
        }
      }
    }

    .block-title {
      margin-bottom: 18px;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
    }

    .form-file {
      margin-top: 0;
      margin-bottom: 0;

      .btn {
        display: inline-block;
        position: relative;
        margin-bottom: 0;
      }
      .custom-file {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
      }
      .custom-file-label {
        display: none;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    &-img {
      max-height: 401px;
    }
  }

  &-content {
    margin-bottom: 40px;
  }

  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    background-color: rgba($white, .9);

    .btn {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      color: #3C37BE;
      background-color: transparent;
      text-decoration: none;
      outline: none;
      box-shadow: none;

      &-next {
        i {
          margin-left: 10px;
        }
      }

      &-back {
        i {
          margin-right: 10px;
        }
      }
    }
  }

  .download {
    text-align: center;

    &-img {
      max-width: 552px;
      margin: 0 auto 20px;
    }

  }

  .addlogo {

    .preview {
      max-width: 450px;
    }

    &-file-list {
      padding-left: 25px;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }

    &-logo {
      max-width: 158px;
      background-color: #ccc;
    }
  }

  .app-settings {
    .application-cards {
      max-width: 100%;

      &-col:last-child {
        margin-bottom: 0;
      }

      &-scroll {
        max-height: 206px;
        margin-bottom: 24px;
        overflow-y: scroll;
      }
    }

    .btn {
      font-weight: 400;
      line-height: unset;
      color: #626ed4;
      background-color: transparent;
      border-radius: 0;
      text-transform: capitalize;
    }
  }

  .finish {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      min-width: 210px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Enroll */
  .enrollusers {

    #invite-form {
    }

    #invite-csv-form {

      .form-group {

        > label {
          display: none;
        }

        .description {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          a {
            font-weight: 400;
           color: #625EC9;
          }
        }

        .custom-file {
          height: 44px;
        }

        .custom-file-label {
          display: flex;
          margin-bottom: 0;
          align-items: center;
          height: 100%;
          border-color: #BCBCBC;
          border-radius: 8px;

          &:after {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: calc(100% + 2px);
            top: -1px;
            background-color: #625EC9;
          }
        }
      }

      .actions {
        text-align: center !important;

        .btn {
          min-width: 170px;
          background-color: #3C37BE;
        }
      }
    }

    &-tabs-nav {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      text-align: center;

      .btn {
        margin-left: 6px;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        color: #3C37BE;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }
    }

    &-copy,
    &-actions {
      text-align: center;
    }

    &-copy {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;

      .btn {
        position: relative;
        margin-left: 10px;
        padding: 0;
        font-size: 16px;
        color: #3C37BE;
        background-color: transparent;
        text-decoration: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  &-pricing {

    &-icon {
      margin-bottom: 14px;
    }

    hr {
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &-text {
      margin-bottom: 24px;
      font-size: 16px;
      color: #020036;

      &_color {
        color: $neonBlue;
      }

      b {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-stripe {
      @media(max-width: 767px) {
        margin-top: 40px;
      }
    }
  }

}

/* Table */
.table {
  th {
    .pl-25 {
      padding-left: 25px;
    }
  }
  td {
    .wrapper {
      position: relative;
      padding-left: 25px;

      .icon {
        position: absolute;
        width: 17px;
        height: 20px;
        top: -1px;
        left: 0;
        line-height: 20px;
      }
    }
  }
}

#user-form {
  textarea {
    min-height: 35px;
    max-height: 150px;
  }
}

/* Menu */
.menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;

  &-actions {
    display: none;
  }

  &-right {
    align-items: center;

    &-link {
      margin-right: 10px;
      padding: 5px;
      font-weight: 500;
      color: #495057;

      &:hover {
        color: #4A548B;
      }
    }

    > div {
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .btn {
    &-popup {
      width: 100%;
      height: 42px;
      white-space: nowrap;
      margin-right: 8px;
      padding-right: 12px;
      padding-left: 12px;
      font-family: Inter, sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: $neonBlue;
      background-color: transparent;
      border: 1px solid rgba(9, 10, 11, .2);
      border-radius: 8px;
      text-transform: capitalize;
      box-shadow: none;
      transition: border-color .3s;

      &:hover {
        border-color: $neonBlue;
      }
    }

    &-profile {
      display: flex;
      font-family: Inter, sans-serif;
      align-items: center;

      .logo {
        width: 88px;
        min-width: 88px;
        height: 24px;
        margin-right: 8px;
        padding: 4px 8px;
        font-size: 8px;
        font-weight: 700;
        line-height: 16px;
        color: rgb(209 122 122 / 80%);
        background-color: #FFCCCC;
        border-radius: 4px;
        text-transform: uppercase;

        &.active {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          background-color: #fff;
        }
      }

      .avatar {
        display: inline-block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        font-size: 10px;
        font-weight: 700;
        line-height: 24px;
        color: $neonBlue;
        background-color: #FAF5FF;
        border: 1px solid #DBDBDB;
        border-radius: 100%;
        overflow: hidden;
      }
    }
  }
}

/* Parent class for set siblings blocks centered one each other */
.parent-block {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-height {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
}

.chart-wrapper {
  max-height: 300px;
}

/* Profile page */
.profile {

  &-top {
    margin-bottom: 20px;
    border-bottom: 1px solid #000;

    &-title {
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }

    &-text {
      margin-bottom: 14px;
    }

    .btn {
      margin-bottom: 20px;
    }
  }
}

#profile-form {
  width: 100%;
}

/* Form */
.form {
  &-title {
    font-size: 18px;
    font-weight: 500;
  }

  &-text {
    margin-bottom: 8px;
  }

  &-control {
    height: 44px;
    padding: 11px 20px;
    font-size: 15px;
    line-height: 20px;
    color: #090A0B;
    background-color: #FFFFFF;
    border-color: #DBDBDB;
    border-radius: 8px;

    &::placeholder {
      color: rgba(9, 10, 11, 0.5);
    }

    &:disabled,
    &:read-only {
      background-color: #FFFFFF;
    }
  }

  &-group {
    &_checkbox {

      label {
        position: relative;
        padding-top: 3px;
        padding-left: 30px;
        line-height: 18px;
        cursor: pointer;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background-color: #FFFFFF;
        border: 1px solid $neonBlue;
        border-radius: 4px;
        order: 1;

        &::before {
          content: '';
          border-bottom: 2px solid $neonBlue;
          border-left: 2px solid $neonBlue;
          transform: translateY(-1px) rotate(-45deg);
          opacity: 0;
          transition: width 0.2s, height 0.2s, opacity 0.2s;
        }
      }

      input:checked + span {
        background-color: #FAF5FF;

        &:before {
          width: 60%;
          height: 30%;
          opacity: 1;
        }
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    &_radio {
      label {
        position: relative;
        margin-right: 20px;
        padding-top: 3px;
        padding-left: 30px;
        cursor: pointer;
      }

      input {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 2px;
        left: 0;
        padding: 4px;
        border: 1px solid $neonBlue;
        border-radius: 0.25rem;

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          background-color: $neonBlue;
          border-radius: 2px;
          transition: width 0.2s, height 0.2s;
        }
      }

      input:checked + span {

        &:before {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

/* Popup */
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 1299;

  &-body {
   text-align: center;
  }

  &-summary {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    &-block {
      width: 80%;
      margin-left: 24px;
      font-weight: 600;
      text-align: left;
    }

    p {
      &.popup-text {
        text-align: left;
      }
    }

    @media(max-width: 991px) {
      align-items: unset;
      justify-content: unset;

      &-block {
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0;
      }

      p {
        &.popup-text {
          text-align: center;
        }
      }
    }
  }

  &-content {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 32px;
    background-color: #FFFFFF;
    border-radius: 6px;
    text-align: center;
  }

  .input-group {
    max-width: 330px;
    margin: 0 auto 20px;

    .error {
      position: absolute;
      bottom: -15px;
      left: 0;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &-text {
    margin-bottom: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .btn {

    &-close {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 4px;
      right: 4px;
      padding: 0;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: #4A548B;
        transform-origin: center;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.export {

    .spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .6);
      z-index: 1;
    }

    .popup-data {
      margin-bottom: 20px;

      &-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-label {
        padding-right: 16px;
        text-align: left;
      }

      > h4 {
        text-align: left;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }

    .summary-content {
      max-width: 900px;
      @media(max-width: 991px) {
        height: 100%;
        max-height: 700px;
      }
    }
  }
}

.ai-summary {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    padding-left: 8px;
  }

  .ai-response-data-title {
    padding: 0;
  }

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

  }
}

.cursor-pointer {
  cursor: pointer;
}

/* Page */
.page {

  &-title {
    margin-bottom: 40px;

    &-box {
      h2 {
        margin-bottom: 0;
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #090A0B;
      }
    }
  }

  &-subtitle {
    max-width: 50%;
    margin-bottom: 20px;
    font-size: 10px;
    color: #383838;
    letter-spacing: 1px;
  }

  &-block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &_border {
      padding: 40px 20px 20px;
      background-color: #fff;
      border: 1px solid rgba(9, 10, 11, 0.2);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 16px;
    }

    &-title {
      margin-bottom: 1.5rem;
      color: #000000;
      text-transform: uppercase;

      h4 {
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
        color: #000000;
      }
    }

    // &-title {
    //   margin-bottom: 0;
    //   font-size: 22px;
    //   font-weight: 600;
    //   line-height: 1.2;
    //   color: #000000;
    // }

    &-subtitle {
      max-width: 50%;
      margin-bottom: 20px;
      font-size: 10px;
      color: #383838;
      letter-spacing: 1px;
    }
  }
}

.page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  div {
    top: 50%;
    left: 50%;
    position: absolute;
  }
}

.link {
  &_google {
    display: inline-flex;
    height: 44px;
    align-items: center;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    color: #757575;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);

    &:hover {
      color: #757575;
      cursor: pointer;
    }

    span.icon {
      display: inline-block;
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 24px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

/* Settings */
.settings {

  &-customization {
    padding: 16px;

    &-title {
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
      color: #333547;
    }

    .nav {
      &-tabs {
        margin-bottom: 24px;
        border-bottom: none;
      }

      &-item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-link {
        display: inline-block;
        padding: 12px 22px;
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: $neonBlue;
        background-color: transparent;
        border-radius: 8px;
        outline: none;
        border: none;
        box-shadow: none;
        text-align: center;
        letter-spacing: 0.1px;
        transition: background-color .3s;
        cursor: pointer;

        &:hover {
          color: #FFFFFF;
          background-color: $neonBlue;
        }

        &.active {
          color: #FFFFFF;
          background-color: $neonBlue;
        }
      }
    }
  }

  &-logo {
    padding-bottom: 40px;

    &-title {
      margin-bottom: 6px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }

    .title {
      margin-bottom: 8px;
      font-weight: 500;
    }

    &-img {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 376px;
      height: 88px;
      background-color: #E8E8E9;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        max-height: 88px;
      }
    }
  }

  .form {

    &-section {
      position: relative;
      padding-top: 1rem;

      &:before {
        content: '';
        position: absolute;
        width: calc(25% - 16px);
        height: 1px;
        top: 0;
        left: 0;
        background-color: #C4C4C4;
      }

      &-title {
        margin-bottom: 0.5rem;
      }

      &-subtitle {
        margin-bottom: 0.5rem;
      }

      &.no-line {
        &::before {
          display: none;
        }
      }
    }

    &-block {
      position: relative;
      margin-bottom: 1rem;
      padding: 20px 20px 30px;
      border: 1px solid rgba(9, 10, 11, 0.2);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 16px;

      .btn-remove {
        position: absolute;
        top: 16px;
        right: 16px;
      }

      &-child {
        padding-left: 16px;
      }

      &-title {
        margin-bottom: 1rem;
        padding-top: 10px;
        padding-right: 88px;
      }

      &-preview {
        max-width: 250px;
        height: 142px;

        img {
          display: block;
          max-width: 100%;
          height: 100%;
        }
      }
    }

    .character-count,
    .file-info {
      padding-top: 8px;
      font-size: 12px;
      line-height: 16px;

      &.success {
        color: #038226;
      }

      &.error {
        color: #DC2D2D;
      }

      span {
        &:last-child {
          font-weight: 500;
        }
      }
    }

    &-btns {
      text-align: right;
    }

    &-file {
      label {
        position: relative;

        .custom-file {
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &-group {
      margin-bottom: 30px;

      legend {
        font-size: 1.2rem;
      }
    }

    &-group_radio,
    &-group_checkbox {

      label {
        position: relative;
        margin-right: 20px;
        padding-top: 0;
        padding-left: 20px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        input {
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          right: 0;
          opacity: 0;
          visibility: hidden;

          &:checked + span:before {
            width: 100%;
            height: 100%;
          }
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 14px;
          height: 14px;
          top: 2px;
          left: 0;
          padding: 2px;
          border: 1px solid #2E2B70;
          border-radius: .25rem;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            background-color: #2E2B70;
            border-radius: 2px;
            transition: width .2s, height .2s;
          }
        }
      }

      &.disabled {
        opacity: 0.5;

        label {
          cursor: unset;
        }
      }
    }

    &-group_checkbox {
      padding-left: 20px;

      > label {
        margin-right: 0;
        padding-left: 0;
        font-weight: 700;

        input {

          &:checked + span:before {
            width: 100%;
            height: 60%;
            opacity: 1;
          }

          &:disabled + span {
            opacity: .5;
          }
        }

        span {
          left: -20px;
          background-color: #2E2B70;

          &:before {
            border-bottom: 2px solid #FFF;
            border-left: 2px solid #FFF;
            transform: translateY(-1px) rotate(-45deg);
            opacity: 0;
            transition: width .2s, height .2s, opacity .2s;
          }
        }
      }

      &-child {
        padding-left: 40px;
      }

      .form-group {

        label {
          margin-right: 0;
          padding-left: 0;
        }

        &:last-child {
          padding-top: 8px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .inline-input {
        display: inline-block;
        width: 100%;
        max-width: 68px;
        margin-right: 5px;
        margin-left: 5px;

        input {
          padding-right: 12px;
          padding-left: 12px;
          text-align: center;
          position: static;
          width: inherit;
          height: inherit;
          opacity: 1;
          visibility: initial;

          &:disabled {
            background-color: #e9ecef;
          }
        }
      }
    }

    &-col-add {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 250px;
    }
  }

  .tab {
    &-content {
      padding: 32px 26px;
    }

    &-pane {
      // padding: 16px;
    }
  }

  &-tab {
    &-block {
      > .title {
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: #090A0B;
      }

      > .subtitle {
        max-width: 600px;
        margin-bottom: 16px;
        font-size: 14px;
        color: #000;
      }

      .addnew-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &-item {
      // padding-bottom: 20px;

      .title {
        margin-bottom: 6px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }
      .text {
        margin-bottom: 12px;
      }

      .image-preview {
        .title {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 500;
        }

        .image {
          position: relative;

          &-wrapper {
            display: flex;
            align-items: center;
            position: absolute;
            width: 100%;
            max-width: 250px;
            height: 142px;
            top: 0;
            left: 0;
          }

          img {
            display: block;
            max-width: 100%;
            height: auto;
            max-height: 142px;
          }
        }
      }
    }
  }

  .separator {
    margin-bottom: 16px;
    border-bottom: 1px solid #c4c4c4;
  }

  .custom-file-label {
    border-color: #4a548b;

    &:after {
      color: #fff;
      background-color: #4a548b;
    }
  }
}

.vector-map-height {
  min-height: calc(100% - 70px);
}

/* Extension */
.ext-configuration{
  background-color: #ececf1;
}

.extension-preview {
  width: 400px;
  padding: 15px 25px 25px;
  color: #FFFFFF;
  transition: background-color 1000ms linear;

  &.good {
    background-color: #0CC778;
  }
  &.bad {
    background-color: #E13A26;
  }
  &.decent {
    background-color: #E1B107;
  }
  &.inprogress {
    background-color: #979797;
  }

  &-wrapper {
    width: 100%;
    max-width: 400px;
    overflow-x: scroll;
  }

  &-header {

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

  }

  &-logo {
    width: 62px;
    height: 15px;

    img {
      height: 100%;
    }
  }

  &-update {
    display: flex;
    align-items: center;
    font-size: 10px;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  &-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
  }

  &-subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  button, a {
    font-size: 13.3333px;
  }

  &.helpdesk {
    background-color: #6B6DC9;

    .extension-preview-update {
      .icon {
        margin-left: 0;
      }
    }
  }
}

.form-helpdesk {
  label i {
    line-height: 16px;
  }
}

/* Img */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Speedometer */
.speedometer {
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  border-radius: 100%;
  z-index: 1;

  .speed {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #656565;
    text-align: center;
    letter-spacing: .5px;
    text-transform: uppercase;
    transform: translate(-50%, -50%);

    > div {
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      color: #090A0B;
    }
  }

  .speed-count {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #090A0B;
    letter-spacing: .5px;
    z-index: 1;
  }

  &-arrow {
    position: absolute;
    width: 50%;
    height: 16px;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: right;
    transition: transform .3s;

    &-color {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: -6px;
      background-color: $neonBlue;
      border: 4px solid #FFFFFF;
      border-radius: 100%;
    }
  }

  &-wrapper {

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
      color: #656565;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: .5px;

      &-icon {
        margin-left: 4px;
      }

      .mdi {
        font-size: 14px;
        line-height: 14px;
        color: rgba(9, 10, 11, .4);
      }
    }
  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    padding: 4px;
    background-color: rgba(9, 10, 11, .1);
    border-radius: 100%;
    transform: translateX(-50%);
    z-index: -1;

    &-inner {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 100%;
    }

    &-half {
      position: absolute;
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      background-color: #fff;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        top: -4px;
        border-radius: 50%;
        box-shadow: 0 1px 0 0 #fff
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .8);
    z-index: 3;
  }
}

/* Scale */
.scale {
  display: flex;
  flex-direction: column;

  &-bar {
    width: 100%;
    height: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    background: rgba(9, 10, 11, .1);

    &-value {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: 4px;
      padding: 4px;
      border: 4px solid #FFFFFF;
      background-color: $neonBlue;
      border-radius: 100%;
      transform: translateX(-50%);
      transition: left .3s;
    }

    &-wrapper {
      position: relative;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  &-items {
    position: relative;
    height: 24px;

    > div {
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &-dimension {
    font-weight: 600;
    text-align: center;
  }

  &-title {
    padding-left: 18px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
  }

  &-subtitle {
    padding-left: 18px;
    font-size: 10px;
    color: #383838;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  &-data {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #656565;
    text-transform: uppercase;
    letter-spacing: .5px;

    &-label {
      margin-right: 8px;
    }

    &-value {
      color: #090A0B;
    }
  }
}

.check-network-health-btn {
  background-color: $neonBlue;
  padding: 4px 8px;
  color: #fff;
  margin-left: 20px;
  &:disabled {
    pointer-events: none;
    opacity: .4;
  }
}
/* User-details */
.user-details {

  &-top {
    position: relative;
    margin-bottom: 32px;

    .spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &-user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .name {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      color: #090A0B;
      text-transform: capitalize;
    }
  }

  &-info {
    .item {
      display: flex;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      &-name {
        width: 106px;
        min-width: 106px;
        padding-right: 10px;
        font-size: 11px;
        font-weight: 600;
        line-height: 18px;
        color: #656565;
        text-transform: uppercase;
        word-break: break-all;
        letter-spacing: .5px;
      }

      &-value {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: #090A0B;
        letter-spacing: .1px;

        span {
          margin-left: 5px;

          &.danger {
            color: #fd6c5a;
          }
        }
      }
    }
  }

  &-tabs {

    .nav {

      &-tabs {
        position: relative;
        border: none;
        z-index: -1;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          border-bottom: 1px solid #DBDBDB;
          z-index: -1;
        }
      }
      &-item {
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }

        &[disabled] {
          opacity: .2;
        }
      }

      &-link {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 0;
        padding: 12px 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 600;
        color: rgba(9, 10, 11, 0.4);
        border: none;
        letter-spacing: .5px;
        text-transform: uppercase;
        transition: color .3s;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $neonBlue;
          transition: width .3s;
        }

        .count,
        .warning {
          display: block;
          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-left: 15px;
          padding-right: 2px;
          padding-left: 2px;
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
          background-color: transparent;
          color: #fff;
          border-radius: 100%;
          text-align: center;

          &.bad {
            background-color: #E00707;
          }
        }

        .warning {
          font-size: 15px;
          background-color: #ff8d00;
        }

        &.active,
        &:hover {
          color: #4F46E5;
          background: initial;

          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .tab {
    &-content {
      padding-top: 56px;

      .spinner-wrapper {
        text-align: center;

        .spinner-border {
          color: #625EC9;
        }
      }
    }

    &-title {
      margin-bottom: 20px;

      h4 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0;
        font-size: 22px;

        span {
          font-weight: 400;

          &.time {
            margin-left: 8px;
            font-size: 14px;
          }

        }
      }
    }

    &-subtitle {
      max-width: 50%;
      font-size: 10px;
      color: #383838;
      letter-spacing: 1px;
    }

    &-block {
      margin-bottom: 80px;

      &-title {
        margin-bottom: 4px;
        font-size: 15px;
        font-weight: 700;
        line-height: 21px;
        color: #090A0B;
        text-transform: capitalize;

        span {
          margin-left: 4px;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          color: #656565;
          text-transform: uppercase;
        }

        button {
          padding: 0;
          border: none;
          color: #626ed4;
          background-color: transparent;
          transition: color .3s;

          &:hover {
            color: #000;
          }
        }

        .time {
          margin-left: 8px;
          font-size: 11px;
        }
      }

      &-subtitle {
        max-width: 50%;
        margin-bottom: 24px;
        font-size: 12px;
        line-height: 16px;
        color: #656565;
        text-transform: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &_network {
        .tab-block-title {
          margin-bottom: 20px;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
          color: #656565;
          letter-spacing: .5px;
          text-transform: uppercase;
        }

        .list-block {
          &-item {
            display: flex;
            justify-content: space-between;
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 13px;
            line-height: 18px;
            border-bottom: 1px solid #DBDBDB;
            letter-spacing: .1px;

            &:first-child {
              border-top: 1px solid #DBDBDB;
            }
          }

          &-label {
            margin-right: 10px;
            font-weight: 500;
            color:#090A0B;
          }

          &-value {
            font-weight: 700;

            &.text-good {
              color: #0CC778;
            }
            &.text-decent {
              color: #D8B655;
            }
            &.text-poor {
              color: #E00707;
            }
          }
        }

      }
    }

    &_network {
      .dashboard-stats-card {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;

        .card {
          &-body {
            flex-direction: column;
            align-items: flex-start;
            min-height: unset;
            padding: 0;
          }

          &-title {
            font-size: 11px;
            line-height: 16px;
            color: #656565;
            letter-spacing: .5px;
          }
        }
      }

      .tab-card {

        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 4px;
          font-size: 11px;
          font-weight: 600;
          line-height: 16px;
          color: #656565;
          letter-spacing: .5px;
          text-transform: uppercase;

          > span {
            margin-left: 4px;
            font-size: 16px;
          }
        }

        &-value {
          font-size: 22px;
          line-height: 28px;
          color: #090A0B;

          .wifi-status-icon {
            width: 24px;
            min-width: 24px;
            height: 24px;
          }
        }

        .spinner-wrapper {

        }

      }
    }

    &_network {
      .network-tests {
        display: flex;
        flex-direction: column;
        height: calc(100% - 30px);

        .tab-block_network {
          margin-top: auto;
        }
      }
    }

    &_device {
      .tab-block {
        margin-bottom: 80px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_device,
    &_applications {
      .card-body {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        height: 100%;
        margin-bottom: 0;
        padding: 32px 24px;
        background-color: rgba(9, 10, 11, .02);
        border: 1px solid #DBDBDB;
        border-radius: 8px;
      }

      .card-title {
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
        color: #656565;
        letter-spacing: .5px;
      }

      .card-text {
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    &-os-image {
      width: 100%;
      max-width: 150px;
      margin-bottom: 20px;
    }
  }

  .wifi-status-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .dashboard-stats-card {

    .card-title {
      flex: 1;
      margin-bottom: 4px;
      margin-right: 10px;
      font-weight: 600;
      text-align: left !important;
      text-transform: uppercase;

      > span {
        display: flex;
        align-items: center;
      }

      i {
        font-size: 16px;
        color: rgba(9, 10, 11, 0.4);
      }
    }

    .card-body {
      display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: center;
      min-height: 80px;
    }

    .card-text {

      span {
        display: flex;
        align-items: center;

        span:not(.wifi-status-icon) {
          margin-left: 8px;
          font-size: 14px;
          color: #979797;
        }
      }

    }
  }

  .col-card {
    margin-bottom: 24px;

    .card {
      height: 100%;

      &-body {
        display: flex;
        flex-direction: column;
      }

    }
  }

  .recent-tests-card {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0;

    .card-body {
      padding: 0;
    }

    .card-title {
      margin-bottom: 8px;
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      color: #000;
      text-transform: uppercase;
    }

    .card-subtitle {
      margin-top: 5px;
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: 10px;
      color: #383838;
      letter-spacing: 1px;
    }

  }

  .card-map {
    background-color: transparent;
    box-shadow: none;

    .card-body {
      padding: 0;
    }

    .card-title {
      margin-bottom: 8px;
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: 14px;
      font-weight: 400;
      color: #000;
      text-transform: uppercase;
    }

    .card-subtitle {
      margin-top: 5px;
      padding-right: 1rem;
      padding-left: 1rem;
      font-size: 10px;
      color: #383838;
      letter-spacing: 1px;
    }

    .vector-map-height {
      min-height: unset;
      margin-top: auto;
      padding: 0 1rem .5rem;
      background-color: #f7f7f7;
    }
  }

  .charts-wrapper {
    max-width: 144px;

    .separator {
      margin-top: 16px;
      margin-bottom: 20px;
      border: 1px solid #DBDBDB;
    }

    .speedometer {

      &-wrapper {
        padding-right: 8px;
        padding-left: 8px;
        overflow: hidden;
      }
    }

    .scale {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  .speedometer {

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        margin-bottom: 20px;
      }
    }
  }

  .streaming-capabilities {
    padding-left: 0;
    color: #000;
    border-left: none;
  }
}

/* Device-info */
.device-info {

  &-item {

  }

  &-name {
    margin-bottom: 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #656565;
    letter-spacing: .5px;
  }

  &-value {
    font-size: 22px;
    line-height: 28px;
    color: #090a0b;
  }
}

.tab_summary_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  position: relative;
  z-index: 1;
}

.divider {
  border: 1px solid #DBDBDC;
}

/* Applications table */
.applications-table {

  tbody {
    td {
      border-bottom: 1px solid #e9ecef;
    }
  }

  .applications-link-blok {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: rgba(9, 10, 11, .1);
      text-transform: uppercase;
    }

    .name {
      margin-left: 16px;
      text-transform: capitalize;
    }
  }

  .application-status-text,
  .application-page-load {
    &.good {
      color: #00C785;
    }

    &.descent {
      color: #FEBA00;
    }

    &.poor {
      color: #FD6C5A;
    }
  }
}

/* Input type select */
select {
  padding-right: 30px !important;
  appearance: none;
  background-image: url('../../images/chevron-down.svg');
  background-repeat: no-repeat;
  background-size: 30px 20px;
  background-position: center right;
}

/* Iq overview */
.iqoverview {
  .card-map {
    .vector-map-height {
      margin-top: 0;
    }
  }
}

/* Chart blocks */
.chartblocks {

  .card {
    align-items: center;
    height: 100%;
    padding: 20px 16px;
    background-color: #f7f7f7;

    &-body {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        margin-bottom: 20px;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
        text-align: center;
      }

    }

    &-tooltip {
      position: absolute;
      top: 16px;
      right: 14px;
    }
  }

  .row {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 66.66667%;
      height: 1px;
      background-color: #C4C4C4;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }

    > div {
      position: relative;
      margin-bottom: 24px;

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 12px;
        bottom: 100%;
        left: 50%;
        background-color: #C4C4C4;
        transform: translateX(-50%);
      }
    }
  }

  &-top {

    .card-body {
      .title {
        padding-right: 20px;
        padding-left: 20px;
      }

      &:after {
        top: 100%;
        bottom: auto;
      }
    }

    .row {

      &:before {
        display: none;
      }

      > div {
        &:after {
          top: 100%;
          bottom: auto;
        }
      }
    }
  }

  .speedometer {
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;

    .speed {
      font-size: 0;

      > div {
        font-size: 36px;
      }

      &-count {
        .limit {
          padding-right: 8px;
          font-size: 0;

          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  .scale {
    max-width: 400px;
    margin: auto auto 0;

    &-title {
      padding-left: 0;
      text-align: center;
    }
  }
}

#invite-csv-form {
  .description {
    margin-bottom: .5rem;
    font-size: 12px;

    a {
      font-weight: 700;
      color: #5b626b;
    }
  }

  .custom-file-label {
    padding-right: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/* Application cards */
.application-cards {

  &-col {
    margin-bottom: 24px;
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    height: 100%;
    margin-bottom: 0;
    padding: 1rem 1rem 1rem 2rem;
    color: #000000;
    background-color: #F7F7F7;
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid rgba(9, 10, 11, 0.2);

    &-info {
      width: 100%;
      max-width: 300px;
      padding-right: 1rem;
    }

    &-value {
      width: 100%;
      max-width: 300px;
      padding-left: 1rem;
      text-align: right;
      text-transform: uppercase;

      &_center {
        padding-left: 0;
        text-align: center;
      }
    }

    &-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-transform: uppercase;

      a {
        color: #000;
      }
    }

    &-url {
      font-size: 10px;
      text-transform: uppercase;

      a {
        color: #000;
      }
    }

    &-answer {
      font-size: 1.4rem;
      line-height: 1.4rem;

      span {
        margin-left: 8px;
        font-size: 14px;
        color: #979797;
        text-transform: none;
      }

      &.good {
        color: #00C785;
      }

      &.descent {
        color: #FEBA00;
      }

      &.poor {
        color: #FD6C5A;
      }
    }

    &-description {
      font-size: 10px;

      &.poor {
        color: #FD6C5A;
      }
    }

    .status {
      position: absolute;
      width: 10px;
      height: 20px;
      top: 50%;
      left: 12px;
      margin: 0;
      border-radius: 12px;
      background-color: #ccc;
      transform: translate(0, -50%);

      &.good {
        background-color: #5EE101;
      }

      &.poor {
        background-color: #FD6C5A;
      }

      &.descent {
        background-color:#FEBA00;
      }
    }

    &_custom {
      .card {

        &-info {
          width: 100%;
        }

        &-value {
          flex: 1;
          min-width: 140px;
        }
      }
    }
  }

  &_tab {

    .card {
      flex-wrap: nowrap;
    }
  }
}

/* App-settings */
.app-settings {

  .MuiInput-root {
    width: 100%;

    &:before,
    &:after {
      display: none;
    }
  }

  input {
    height: 27px;
    padding: 4px 8px;
    background-color: #EAEAEA;
    border: none;
    transition: background-color, .3s;

    &::placeholder {
      font-style: italic;
      color: #969696;
    }

    &:focus {
      background-color: #ffffff;
    }
  }

  .application-cards {
    max-width: 50%;
  }

  .card {
    min-height: 91px;
    flex-wrap: nowrap;

    &-info {
      width: 50%;

      a {
        color: #808080;
      }
    }

    &-name {
      margin-bottom: 5px;

      > a {
        font-weight: 700;
      }
    }

    &-card-url {
      a {
        font-size: 13px;
      }
    }

    &-add {
      .btn {
        color: $neonBlue;
        border-color: $neonBlue;
        border-radius: 27px;

        &:hover {
          color: #fff;
        }

        &:disabled:hover {
          color: $neonBlue;
        }
      }
    }

    &-actions {
      display: flex;
      width: 44px;

      .btn {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        padding: 0;

        &:first-child {
          margin-left: 0;
        }

        i {
          line-height: 20px;
        }
      }
    }

    &-value {
      // flex: none;

      .spinner-border {
        width: 1rem;
        height: 1rem;
        border-color: #626ed4;
        border-right-color: transparent;
      }

      .response {
        text-transform: capitalize;

        &.good {
          color: #5EE101;
        }

        &.poor {
          color: #FD6C5A;
        }
      }

    }
  }

  &-title {
    font-size: 32px;
    color: #090A0B;
  }

  &-content {
    margin-top: 23px;
    border: 1px solid rgba(9, 10, 11, 0.2);
    border-radius: 16px;
    padding: 30px;
    //background: rgba(9, 10, 11, 0.02);
    background-color: #fff;

    &-text {
      font-size: 15px;
      margin-top: 30px;
      color: #656565;
    }

    &-critical-title {
      font-size: 22px;
      font-weight: 700;
      color: #090A0B;
      margin: 10px 0 30px;
    }
  }
}

.user-details {
  &-top {
    position: relative;
    &-onboarding {
      position: fixed;
      z-index: 100;
      right: 350px;
    }
  }
  &-tabs {
    position: relative;
    z-index: 2;
  }
}

@media(min-width: 1500px) {
  .tab_summary_wrapper {
    grid-template-columns: 640px 1fr;
  }
}

@media(max-width: 1500px) {
  .application-cards {
    .card {
      &-answer {
        &.text {
          font-size: 12px;
        }
      }
    }
  }
}

@media(max-width: 1200px) {
  /* user details */
  .user-details {
    &-top {
      flex-direction: column;
    }

    &-user {
      width: 100%;
      padding-right: 0;
    }

    &-tabs {
      .nav {
        justify-content: flex-start;
      }
    }

    .tab {

      &-subtitle {
        max-width: 100%;
      }

      &-block {
        &-subtitle {
          max-width: 100%;
        }
      }
    }
  }

  /* app settings */
  .app-settings {

    .application-cards {
      max-width: 100%;
    }
  }

  /* Page */
  .page {
    &-subtitle {
      max-width: 100%;
    }
  }
}

@media(max-width: 1199px) {

  .chartblocks {

    .row {
      > div:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }

    &-top {
      .row {
        > div:after {
          display: none;
        }
      }
    }
  }

  .extension-preview {
    margin-top: 20px;
  }

}

@media(max-width: 1024px) {
  .trial {
    flex-wrap: wrap;
    font-size: 12px;
  }
}

@media(max-width: 991px) {
  .tab_summary_wrapper {
    grid-template-columns: 1fr;
  }
  .user-details {
    .col-card {
      .card {
        height: auto;
      }
    }
  }

  /* Modal */
  .modal {

    &-onboarding {

      .modal {

        &-header {
          padding: 1rem;

          .close {
            position: static;
            margin: 0;
          }
        }
      }

    }
  }

  .popup-summary {
    flex-direction: column;
  }

  /* Onboarding */
  .onboarding {
    &-stepper {
      margin-bottom: 30px;

      .MuiStepper-root {
        padding-right: 0;
        padding-left: 0;
      }
      .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
      }
      .MuiStepConnector-alternativeLabel {
        top: 11px;
      }
      .MuiStepConnector-line {
        border-top-width: 2px;
      }
      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 12px;
        font-size: 12px;
      }
    }

    &-step {
      padding-right: 16px;
      padding-left: 16px;

      .title {
        margin-bottom: 16px;
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

@media(max-width: 767px) {

  /* Header */
  .header {
    &-invite {
      margin-right: 8px;

      &> .btn {
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }

  .activities-filter {
    flex-wrap: wrap;

    .date-range-piker {
      width: 100%;
      margin-bottom: 12px;
      margin-right: 0 !important;

      >div:first-child {
        flex-wrap: wrap;
      }

      .input-group {

        .start-date-input-group {
          width: 50%;

          .react-datepicker-wrapper {
            flex: 1;

            input {
              width: 100%;
            }
          }

          p {
            display: block;
            width: 100%;
          }
        }

        .end-date-input-group {
          flex-wrap: wrap;
          width: 50%;

          .react-datepicker-wrapper {
            flex: 1;

            input {
              width: 100%;
            }
          }

          p {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .search-by-column {
      width: 100%;
      margin-bottom: 12px;

      > div {
        flex-wrap: wrap;

        .input-group {
          margin-left: 0 !important;
        }
      }
    }

    .ml-3 {
      margin-left: 0 !important;
    }
  }

  /* Speedometer */
  .speedometer {
    margin-right: auto;
    margin-left: auto;

    &-wrapper {
      max-width: unset;
    }
  }
}

@media(max-width: 600px) {
  .menu {
    .btn {
      &-popup {
        display: none;
      }
      &-profile {
        display: flex;
      }
    }
  }

  .trial {
    padding-right: 30px;
    padding-left: 30px;

    .btn-close {
      right: 0;
      padding-right: 4px;
      padding-left: 4px;
    }

    .mobile-hide {
      display: none;
    }
  }

  /* onboarding */
  .onboarding {
    &-stepper {
      .MuiStepLabel-labelContainer {
        display: none;
      }
    }
  }
}

@media(max-width: 500px) {

  .activities-filter {

    .date-range-piker {

      .input-group {
        flex-wrap: wrap;
        margin-bottom: 12px;

        .start-date-input-group {
          width: 100%;
        }

        .end-date-input-group {
          width: 100%;
        }
      }
    }
  }

  /* user details */
  .user-details {
    .nav {
      &-item {
        width: calc(50% - 4px);
      }
      &-link {
        height: 100%;
      }
    }
  }

  /* device-info */
  .device-info {
    flex-direction: column;
  }

  /* app settings */
  .app-settings {

    .card {

      &-actions {
        position: absolute;
        top: 5px;
        right: 16px;
      }

      &_custom {
        display: block;

        .status {
          top: 35px;
          transform: translateY(0);
        }

        .card-info {
          width: 100%;
          margin-bottom: 5px;
          padding-right: 0;
        }
      }

      &_custom {
        .card {

          &-info {
            max-width: 100%;
          }

          &-value {
            max-width: 100%;
          }
        }
      }
    }
  }

}
@media(max-width: 499px) {
  .application-cards {
    &_tab {
      .card {
        flex-direction: column;
        align-items: flex-start;

        &-info {
          margin-bottom: 10px;
          padding-right: 0;
        }

        &-value {
          padding-left: 0;
          text-align: left;

          &_center {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .onboarding {
    &-stepper {
      margin-bottom: 30px;

      .MuiStepper-root {
        padding-right: 0;
        padding-left: 0;
      }
      .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
      }
      .MuiStepConnector-alternativeLabel {
        top: 9px;
      }
    }
  }
}

@media(max-width: 350px) {
  .app-settings {
    .application-cards {
      .card {
        flex-direction: row;
        align-items: center;

        &-info {
          margin-bottom: 0;
          padding-right: 1rem;
        }

        &-value {
          text-align: right;
        }

        &_custom {
          .card {

            &-info {
              margin-bottom: 5px;
              padding-right: 0;
            }

          }
        }
      }
    }
  }

  .application-cards {

    .card {
      flex-direction: column;
      align-items: flex-start;

      &-info {
        margin-bottom: 10px;
        padding-right: 0;
      }

      &-value {
        text-align: left;
      }
    }
  }
}

.main-checkbox {
  appearance: none;
  background-color: #FAF5FF;
  border: 1px solid #E8D5FB;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  position: relative;

  &:checked:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid $neonBlue;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.main-radio {
  appearance: none;
  background-color: #FAF5FF;
  border: 1px solid #E8D5FB;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  position: relative;

  &:checked:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
    border: 2px solid $neonBlue;
    border-radius: 50%;
    background-color: $neonBlue;
  }
}

.main-input {
  display: flex;
  flex-direction: column;

  input, select {
    border: 1px solid #DBDBDB;
    outline: none;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    height: 44px;
    width: 100%;
    max-width: 240px;
    padding: 12px 20px;

    &::placeholder {
      color: rgba(9, 10, 11, 0.5);
    }
  }

  select {
    background-image: url('../../images/select.svg');
    background-color: #fff;
    width: 240px;
  }

  label {
    font-size: 13px;
    color: #090A0B;
    font-weight: bold;

    > span {
      color: #DC2D2D;
      margin-left: 4px;
    }
  }

  .success, .error {
    margin-top: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .success {
    color: #038226;
  }

  .error {
    color: #DC2D2D;
  }

  &-icon {
    position: relative;

    button {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      cursor: pointer;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
}

.test-results {
  &-button {
    display: flex;
    justify-content: flex-end;
  }

  &-table-container {
    margin: 1px;
    padding: 40px 0;
    background-color: #FAFAFA;
    border: 1px solid rgba(9, 10, 11, 0.2);
    border-radius: 16px;
  }

  &-date-picker {
   input, select {
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    max-width: 200px;
    height: 44px;
   }
  }
}

.application-status {
  min-width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #2F3941;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    font-size: 16px;
    color: #FFFFFF;
  }
  &-point {
    background-color: #00BA34;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    &.good {
      background-color: #00BA34;
    }
    &.bad {
      background-color: #E13A26;
    }
    &.decent {
      background-color: #E1B107;
    }
    &.inprogress {
      background-color: #979797;
    }
  }

  &.good {
    background-color: #00BA34;
  }
  &.bad {
    background-color: #E13A26;
  }
  &.decent {
    background-color: #E1B107;
  }
  &.inprogress {
    background-color: #979797;
  }

}

.map-view {
  .filter-button {
    .dropdown-menu {
      width: 500px;
      padding: 10px;
    }
  }
}

.filter {
  &-block {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9ecef;

    &:last-child {
      border-bottom-color: transparent;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .btn-link {
        padding: 0;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
    }

    &-clear {
      flex: 1;
      text-align: right;
    }

    legend {
      font-size: 16px;
    }

    .date-range-piker {
      input {
        max-width: 100% ;
      }
    }
  }

  .filter-block .form-group_checkbox {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .filter-block .form-group_radio {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.map {

  // &-block-wrapper {
  //   &.fixed {
  //     position: fixed;
  //     width: 100%;
  //     height: 100%;
  //     top: 0;
  //     left: 0;
  //     z-index: 1299;

  //     .mapboxgl-canvas {
  //       width: 100% !important;
  //       height: 100% !important;
  //     }
  //   }
  // }

  .btn-fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 29px;
    height: 29px;
    top: 78px;
    right: 10px;
    padding: 5px;
    font-size: 28px;
    color: #000000;
    background-color: #FFFFFF;
    border-radius: 4px;
    z-index: 1;

    &.top-right {
      top: 10px;
    }
  }

  &-nav {
    display: flex;
    justify-content: space-between;
  }

  .theme-menu {
    color: #090A0B;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    gap: 10px;

    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      margin: 0;
    }

    input[type='checkbox'] {
      margin-left: 10px;
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .slider {
      background-color: $neonBlue;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $neonBlue;
    }

    input:checked + .slider:before {
      transform: translateX(18px);
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  &-block-wrapper {
    position: relative;
  }

  &-polygons {
    position: absolute;
    width: 29px;
    top: 10px;
    right: 49px;
    z-index: 3;

    .btn-polygons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      height: 29px;
      padding: 5px;
      color: #000000;
      background-color: #FFFFFF;
      border-radius: 4px;

      .icon-wrapper {
        display: inline-block;
        width: 12px;
        height: 12px;
        color: #FFFFFF;
        background-color: #000000;
        font-size: 10px;
        line-height: 12px;
        border-radius: 2px;
        text-align: center;
      }
    }

    &-item {
      display: flex;

      .btn-link {
        text-transform: capitalize;
      }
    }

    .btn-remove {
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }

    .spinner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .5);
    }

    .dropdown-menu {
      min-height: 50px;
      max-height: 200px;

      &.show {
        display: flex;
      }
    }

    .dropdown-scroll {
      width: 100%;
    }
  }

  &-block-wrapper {
    &.fixed {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1299;

      .mapboxgl {
        &-map {
          height: calc(100% - 58px) !important;
          margin-top: 58px;
          position: relative;
        }
        &-canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}

.rbt-token {
  color: $neonBlue;
  background-color: #e5e4ff;
}

input.settings-trigger-input {
  position: static;
  width: inherit;
  height: inherit;
  opacity: 1;
  visibility: initial;
}

.drawer {
  position: relative;

  .MuiDrawer-paper {
    .drawer-close {
      position: absolute ;
        width: 24px;
        min-width: unset;
        height: 24px;
        top: 32px;
        right: 32px;
        padding: 0;
        font-size: 24px;
        line-height: 24px;
        color: #838383;
        background-color: transparent;
        border: none;
        text-align: center;
        cursor: pointer;
        z-index: 1;
    }
  }

  &-title {
    padding-right: 30px;
    font-size: 24px;
    font-weight: 600;
    color: #020036;
  }
}

.dropzone {

  &--select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 140px;
    padding: 24px 16px;
    background-color: #FFFFFF;
    border: 1px dashed rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    > div {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 5px;
    font-size: 20px;
    color: #090A0B;
    background-color: #DBDBDB;
    border-radius: 100%;
  }

  &-text {
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);

    &-link {
      margin-right: 5px;
      color: $neonBlue;
      text-decoration: underline;
    }
  }

  &-accept {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &-files {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);

    > div {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.deploy-google-admin {
  .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .6);
    z-index: 2;
  }
}

@keyframes bounceX {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}

.featured-issue-btn {
  padding: 8px 14px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #020036;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10);
  &:hover {
    animation: bounceX 1s;
  }
}
