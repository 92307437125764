.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px;

  .row {
    display: flex;
    align-items: center;
    gap: 10px;
    & .title {
      font-weight: bold;
    }
    & .value {
      padding: 5px;
      background-color: rgba(0, 0, 0, .05);
      border-radius: 4px;
    }
  }
  .copy-button {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid lightgray;
    &:hover {
      background-color: #eaeaea;
    }
  }
}
