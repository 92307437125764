// PRO SIDEBAR
$sidebar-bg-color: #FFFFFF !default;
$sidebar-color: #000000 !default;
$sidebar-width: 300px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #4F46E5 !default;
$submenu-bg-color: #FFFFFF !default;
$submenu-bg-color-collapsed: #FFFFFF !default;
$icon-bg-color: transparent !default;
$icon-size: 20px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.rcs-custom-scroll{min-height:0;min-width:0}.rcs-custom-scroll .rcs-outer-container{overflow:hidden}.rcs-custom-scroll .rcs-outer-container .rcs-positioning{position:relative}.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar{opacity:1;transition-duration:.2s}.rcs-custom-scroll .rcs-inner-container{overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch}.rcs-custom-scroll .rcs-inner-container:after{content:"";position:absolute;top:0;right:0;left:0;height:0;background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);pointer-events:none;transition:height .1s ease-in;will-change:height}.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after{height:5px;transition:height .15s ease-out}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container{user-select:none}.rcs-custom-scroll .rcs-custom-scrollbar{position:absolute;height:100%;width:6px;right:3px;opacity:0;z-index:1;transition:opacity .4s ease-out;padding:6px 0;box-sizing:border-box;will-change:opacity;pointer-events:none}.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl{right:auto;left:3px}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar{opacity:1}.rcs-custom-scroll .rcs-custom-scroll-handle{position:absolute;width:100%;top:0}.rcs-custom-scroll .rcs-inner-handle{height:calc(100% - 12px);margin-top:6px;background-color:#DBD9FF;border-radius:3px}

.pro-sidebar {
  font-family: 'Inter', sans-serif;
  font-weight: 600;

  &.collapsed {

    .sidebar-header-wrapper .btn-link {

      img {
        transform: rotate(0deg);
      }

      span {
        opacity: 0;
        visibility: hidden;
      }
    }

    .pro-sidebar-inner {
      .sidebar-footer-wrapper {
        padding-right: 8px;
        padding-left: 8px;

        .btn {
          text-align: center;

          .mdi-menu-open {
            opacity: 0;
            visibility: hidden;
          }

          .mdi-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }

    .pro-menu > ul > .pro-menu-item {

      &.pro-sub-menu {
        padding-right: 8px;
        padding-left: 8px;
        border-bottom-color: transparent;
  
        .pro-inner-list-item {
          padding: 8px;
          background-color: #FFFFFF;
          border-radius: 8px;
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, .7);
  
          > .popper-inner {
            padding-left: 0;
          }
        }
      }
    }
  }

  &-inner {
    position: relative;

    .pro-sidebar-layout {
      //overflow: unset;
      overflow: visible!important;

      .pro-sidebar-content {
        overflow: hidden;
        border-right: 1px solid #DBDBDB;
      }

      .pro-sidebar-footer {
        position: relative;
        border-top: none;

        .sidebar-footer-wrapper {
          padding: 12px;
        }

        .btn {
          width: 100%;
          font-size: 20px;
          text-align: right;

          span {
            display: block;
            position: relative;
          }

          .mdi-menu-open {
            position: absolute;
            top: 50%;
            right: 0;
            top: 50%;
            right: 0%;
            transform: translateY(-50%);
            transition: opacity .3s, visibility .3s;
          }

          .mdi-menu {
            top: 50%;
            right: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.2);
          opacity: 1;
          visibility: visible;
          transition: opacity .3s, visibility .3s;
        }
      }
    }
  }



  .btn-link {
    text-decoration: none;
    color: $sidebar-color;
    padding: 0;
    box-shadow: none;

    &:hover {
      color: $highlight-color;
    }
  }

  .pro-menu {
    padding-top: 0;
    padding-bottom: 0;

    .pro-menu-item {
      &.pro-sub-menu {
        .pro-inner-list-item {
          padding: 12px 0 4px 30px;
        }
      }
    }

    > ul {
      > .pro-menu-item {
        padding: 8px 12px;
        border-bottom: 1px solid #DBDBDB;

        &:last-child {
          border-bottom-color: transparent;
        }

        > .pro-inner-item {
          padding: 12px 16px !important;
          border-radius: 8px;
          transition: color .3s, background-color .3s;

          &:hover {
            color: #4F46E5;
            background-color: #DBD9FF;
          }

          .pro-icon-wrapper {
            font-size: 20px;
          }
        }

        &.active {
          > .pro-inner-item {
            color: #4F46E5;
            background-color: #DBD9FF;

            a {
              color: #4F46E5;
            }
          }
        }
      }
    }

    &-item {

      &.pro-sub-menu {

        > .pro-inner-item {
          padding: 12px 16px !important;
          border-radius: 8px;
          transition: color .3s, background-color .3s;

          > .pro-arrow-wrapper {
            right: 18px !important;

            .pro-arrow {
              transform: rotate(45deg) !important;
            }
          }

          .pro-icon-wrapper {
            font-size: 20px;
          }

          &:hover {
            color: #4F46E5;
            background-color: #DBD9FF;

            > .pro-arrow-wrapper .pro-arrow {
              border-color: #4F46E5 !important;
            }
          }

          &:focus {
            color: #4F46E5;
          }
        }
      }

      &.open {
        > .pro-inner-item {
          color: #4F46E5;
          background-color: #DBD9FF;

          &:hover {
            color: #4F46E5;
            background-color: #DBD9FF;
          }

          > .pro-arrow-wrapper .pro-arrow {
            border-color: #4F46E5 !important;
            transform: rotate(225deg) !important;
          }
        }
      }

      .pro-inner-list-item {

        ul {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        .pro-menu-item {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .pro-inner-item {
          padding: 0 !important;
        }

        a,
        .btn {
          display: block;
          width: 100%;
          padding: 12px 16px !important;
          font-size: 13px;
          font-weight: 600;
          border-radius: 8px;
          text-align: left;
          transition: color .3s, background-color .3s;

          &:hover {
            color: #4F46E5;
            background-color: #DBD9FF;
          }

          &:focus {
            color: #4F46E5;
          }

          &.active {
            color: #4F46E5;
            background-color: #DBD9FF;
          }
        }
      }
    }

    &-inner-item {
      padding: 12px 16px;
    }
  }

  .pro-menu .pro-menu-item .pro-item-content {
    font-size: 13px;
    // font-weight: 00;
  }

  .pro-menu .pro-inner-list-item .pro-item-content {
    font-size: 14px;
    font-weight: 400;
  }

  .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    display: none;
  }

  .sidebar-header-wrapper {
    padding: 20px 24px;

    .btn-link {
      display: flex;
      align-items: center;
      white-space: nowrap;

      span {
        margin-left: 10px;
        font-size: 15px;
        white-space: nowrap;
        opacity: 1;
        visibility: visible;
        transition: opacity .3s, visibility .3s;
      }

      img {
        transform: rotate(180deg);
        transition: transform .3s;
      }
    }
  }

  .sidebar-footer-wrapper {
    display: block;
    text-align: center;
    padding: 20px 24px;
    color: #fff;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s, visibility .3s;
  }

  @media(max-width: 768px) {
    &.collapsed {
      width: 240px;
      min-width: 240px;
    }

    &-header {
      display: none;
    }
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: $header-height;
  left: 0;
  z-index: 1001;
  height: calc(100% - $header-height);
  font-family: 'Poppins', sans-serif;
  transition: top .2s, left .3s, height .2s;

  &_top {
    top: $header-height + $trial-height;
    height: calc(100% - #{$header-height + $trial-height});
  }

  &-btn {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 100%;
    background-color: #2E2B70;
    top: 50%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translateY(-50%);

    img {
      transform: rotate(0deg);
      transition: transform .3s;
    }
  }

  &.active {

    .sidebar-btn {
      img {
        transform: rotate(180deg);
      }
    }
  }

  @media(max-width: 768px) {
    left: -240px;

    &-btn {
      display: flex;
    }

    &.active {
      left: 0;
    }
  }
}


main {
  transition: width, left, right, 0.3s;
  padding-left: 0;
  overflow: hidden;
  @media (min-width: 769px) {
    padding-left: 80px;

    &.sidebar-open {
      padding-left: 270px
    }
  }
}





