.account-pages {
  //background-image: url("../../../../../../assets/images/login_img.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position-y: calc(200px + 30px);
  background-position-x: 40px;
  background-size: 500px;
  @media screen and (max-width: 400px) {
    background-image: none;
  }
  .blue-header {
    background-color: #ececf1;
    min-height: 150px;
  }
  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .login-form {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 5%;
    margin-bottom: 0;
    border: 1px solid #CECECE;

    &-content {
      padding: 0 48px 30px;
    }

    @media screen and (max-width: 400px) {
      min-height: 100%;
      top: 0;

      .logo-container {
        padding: 20px;
      }

      &-content {
        padding: 0 20px 20px;
      }
    }
  }
  .logo-container {
    margin-bottom: 10px;
    padding: 30px 48px;
    display: flex;
    justify-content: center;
    background-color: #fff;

    .logo-img {
      width: 130px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .auth-header {
    text-align: center;
  }
  .login-header {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    display: block;
  }
  .login-subheader {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
  }

  .form-group label {
    color: #3C37BE;
    margin-bottom: 5px;
  }

  .login-button-container, .forgot-password-container, .reset-bt-container {
    text-align: center;
  }

  .reset-bt-container {
    margin-top: 20px;
  }
  .auth-button {
    border: 1px solid #3C37BE;
    padding: 10px 40px;
    border-radius: 25px;
    color: white;
    background-color: #3C37BE;

    &:hover {
      color: white;
    }
  }
  .forgot-password a{
    color: #3C37BE;
    font-size: 14px;
  }
  .hubbleiq-copy {
    margin-top: auto;
    text-align: center;
    font-size: 10px;
    color: #2b2a2a;
    text-transform: uppercase;
    letter-spacing: 2px;

    @media(max-width: 400px) {
      letter-spacing: 1.8px;
    }
  }

  .login-google {
    text-align: center;

    &-separator {
      position: relative;
      margin-top: 20px;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 50%;
        left: 0;
        background-color: #ccc;
        transform: translateY(-50%);
      }

      span {
        position: relative;
        padding-right: 10px;
        padding-left: 10px;
        color: #2b2a2a;
        background-color: #fff;
        text-transform: uppercase;
      }
    }

  }

  .trial-popup {
    position: fixed;
    width: 100%;
    max-width: 480px;
    top: 24px;
    right: 24px;
    padding: 30px 48px;
    color: #FFF;
    background-color: #3C37BE;
    border-radius: 23px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -24px;
      right: 60px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 24px solid #3C37BE;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &-logo {
      max-width: 180px;
      margin-bottom: 8px;
    }

    &-text {
      margin-bottom: 14px;

      > span {
        font-weight: 700;
      }
    }

    &-img {
      max-width: 384px;
      margin-bottom: 14px;
    }

    .btn {
      &-link {
        margin-top: 10px;
        padding: 0;
        color: #FFF;
        font-weight: 700;
      }
    }
  }
}
.forgot-password {
  //background-image: url("../../../../../../assets/images/password_reset_bg.svg");
  background-position-x: right;
}

.reset-password {
  background-image: none;
}
