.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapWrapper {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .10);
}
