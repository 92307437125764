$violet_color: #4F46E5;

.installBtn, .installBtnViolet {
  background-color: #fff;
  color: $violet_color;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
}

.installBtnViolet {
  background-color: #4A548B;
  &:is(:hover, :active) {
    background-color: #5965a7;
  }
    &.disabled,
    &:disabled {
      color: #fff;
      background-color: #626ed4;
      border-color: #626ed4;
    }
}
