.streaming-capabilities {
  // border-left: 1px solid rgba(151, 151, 151, 0.4);

  &-label {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 600;
    color: #020036;
  }
  
  .category {
    &-name {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .results {
    flex-wrap: wrap;
    flex: 1;
    flex-grow: 1;
  }
  .result {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    .capStatus {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 8px;

      &.good {
        color: #5EE101;
      }
      &.decent {
        color: #FEBA00;
      }
      &.bad {
        color: #FF5844;
      }
    }
    &-name {
      font-size: 16px;
      letter-spacing: .729px;
      color: #020036;
    }
  }
}

.w-400 {
  width: 400px;
}
