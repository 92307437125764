@import '../../stylesheets/scss/variables';
$dark-blue-color: #020036;

.first_test {
  display: flex;
  flex-direction: column;
  align-items: center;
  .progress_text {
    align-self: flex-start;
    display: flex;
    align-items: baseline;
    margin-bottom: 4px;
    padding-left: 6px;
    font-size: 12px;
    color: $dark-blue-color;
  }
  .progress_bar {
    position: relative;
    width: 100%;
    max-width: 560px;
    height: 8px;
    margin-bottom: 40px;
    background-color: #E9EEF6;
    border-radius: 6px;
    overflow: hidden;

    &_fill {
      width: 50%;
      height: 100%;
      background-color: $main;
      transition: width .3s;
    }
  }
}

.header {
  max-width: 550px;
  width: 100%;
}

.image {
  width: 100%;
  max-width: 800px;

  img {
    display: block;
    max-width: 100%;
  }
}

.text {
  max-width: 680px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  color: $dark-blue-color;
  text-align: center;
}

.carosel {
  width: 100%;
  max-width: 680px;
}

.last_slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}
