$violet_color: #4F46E5;

.onBoardingWrapper {
  display: flex;
  flex-direction: column;
  background: $violet_color;
  padding: 20px;
  border-radius: 10px;
  gap: 12px;
  color: #fff;
  min-width: 335px;
  position: absolute;
  z-index: 1;
  box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.21);
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    right: 50%;
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent $violet_color transparent;
  }
}

.onBoardingWrapper.noArrow {
  &::after {
    content: none;
    display: none;
  }
}

.onBoardingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onBoardingTitle {
  font-size: 18px;
  font-weight: bold;
  flex-wrap: nowrap;
}

.onBoardingText {
  font-size: 14px;
}

.onBoardingCloseBtn {
  color: #fff;
  font-size: 22px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.onBoardingBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.onBoaringBtn {
  background-color: #fff;
  color: $violet_color;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
}
