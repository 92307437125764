//
// _header.scss
//

#page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-bottom: 0;
    padding-top: 0;
    z-index: 1002;
    background-color: $header-bg;
    border-bottom: 1px solid #DBDBDB;
    transition: top .2s;

    &.header_top {
        top: 40px;
    }

    img {
        width: auto;
        margin-bottom: 0;
    }
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: $header-height;
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

    &-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex: 1;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 150px;
            height: 32px;
            margin-right: 10px;
            margin-left: 10px;
            padding: 5px;
            font-size: 13px;
            font-weight: 500;
            color: #333547;
            border-radius: 60px;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: color .3s, background-color .3s;

            span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &.active,
            &:hover {
                color: #fff;
                background-color: #4A548B;
            }
        }
    }

    &-btn {
        display: none;
        box-shadow: none !important;
        outline: none !important;

        &.btn {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 50%;
          right: 20px;
          padding: 0;
          background-color: transparent !important;
          border: none;
          transform: translateY(-50%);

          &:before,
            &:after {
              content: '';
              position: absolute;
              width: 22px;
              height: 2px;
              left: 50%;
              background-color: #4A548B;
              transform: translateX(-50%);
              transition: transform .3s, top .3s, bottom .3s;
            }
            &:before {
              top: 7px;
            }
            &:after {
              bottom: 7px;
            }

          span {
            position: absolute;
            width: 20px;
            height: 2px;
            top: 50%;
            left: 50%;
            background-color: #4A548B;
            transform: translate(-50%, -50%);
            transition: opacity .3s, left .3s;
          }

          &.active {
            &:before {
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
              top: 50%;
              bottom: auto;
              transform: translate(-50%, -50%) rotate(-45deg);
            }

            span {
              left: 100%;
              opacity: 0;
            }
          }
        }
      }
}

.navbar-brand-box {
    width: $navbar-brand-box-width;
    padding-right: 1.75rem;
    padding-left: 1.75rem;

    &.closed {
        padding-right: 18px;
        padding-left: 18px;
    }

    .logo {
        font-family: 'Questrial', sans-serif;
        font-size: 24px;
        line-height: 24px;
        color: #000000;

        &-text {
            display: inline-block;
            margin-right: 2px;
            transition: width .3s;
        }

        &-wrapper {
            display: flex;
            align-items: center;
        }

        &.light {
            color: #fff;
        }

        img {
            transition: height .3s;
        }

        &.closed {
            .logo-text {
                width: 0;
                margin-right: 0;
                overflow: hidden;
            }

            img {
                height: 25px;
            }
        }
    }
}

.logo {
    line-height: 70px;

    .logo-sm {
        display: none;
    }
}

.logo-light {
    display: none;
}

/* Search */

.app-search {
    padding: calc(#{$header-height - 38px} / 2) 0;

    .form-control {
        border: none;
        height: 38px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: $topbar-search-bg;
        box-shadow: none;
        border-radius: 30px;
    }
    span {
        position: absolute;
        z-index: 10;
        font-size: 13px;
        line-height: 38px;
        right: 13px;
        top: 0;
        color: $header-item-color;
    }
}

// Mega menu

.megamenu-list {
    li{
        position: relative;
        padding: 5px 0px;
        a{
            color: $dropdown-color;
        }
    }
}

@media (max-width: 992px) {
    .navbar-brand-box {
        width: auto;
    }

    .logo {

        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: flex;
        }
    }
}

.page-content {
    padding: calc(#{$header-height}) 0 0 0;
    display: flex;
    transition: padding-top .2s;

    &_padding {
        padding-top: $header-height + $trial-height;
    }
}

.header-item {
    height: $header-height;
    box-shadow: none !important;
    color: $header-item-color;
    border: 0;
    border-radius: 0px;

    &:hover {
        color: $header-item-color;
    }
}

.header-profile-user {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 143px;
    height: 40px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    overflow: hidden;
    transition: color .3s, background-color .3s;

    &:hover {
        color: #FFFFFF;
        background-color: rgba(60, 55, 190, .7);
    }

    > div {
        width: 87px;
        height: 24px;
        background: #FFCCCC;
        border-radius: 4px;
        color: #D17A7A;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.noti-icon {
    i {
        font-size: 24px;
        color: $header-item-color;
    }

    .badge {
        position: absolute;
        top: 17px;
        right: 7px;
    }
}

.notification-item {
    .media {
        padding: 0.75rem 1rem;

        &:hover {
            background-color: $gray-100;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $gray-600;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $gray-100;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .bx-fullscreen::before {
            content: "\ea3f";
        }
    }
}

body[data-topbar="dark"] {
    #page-topbar {
        background-color: $header-dark-bg;
    }
    .navbar-header {
        .dropdown.show {
            .header-item {
                background-color: rgba($white, 0.05);
            }
        }
    }

    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {

        .form-control {
            background-color: rgba($topbar-search-bg,0.07);
            color: $white;
            border: 1px solid rgba($white,0.07);
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white,0.5);
        }
    }
}

body[data-sidebar="dark"] {
    .navbar-brand-box {
        //background: $sidebar-dark-bg;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: inline-block;
    }
}
@media (min-width: 768px) {
    .navbar-header-nav {
        display:none;
    }
}
@media (max-width: 992px) {
    .navbar-header {
        &-nav {
            a {
                margin-right: 5px;
                margin-left: 5px;
                max-width: 120px;
            }
        }
    }
}


body[data-layout="horizontal"] {

    .navbar-header{
        padding: 0 calc(#{$grid-gutter-width} / 2) ;
    }

    .navbar-brand-box {
        width: auto;
        padding-left: 0px;
    }
    .page-content {
        margin-top: $header-height;
        padding: calc(55px) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
    }
}

@media (max-width: 992px) {
    body[data-layout="horizontal"] {
        .navbar-brand-box {
            width: auto;
            padding-left: 5px;
        }
        // #page-topbar {
        //     padding: 0px;
        // }
        .page-content {
            margin-top: 10px;
        }
    }
}

@media(max-width: 768px) {

    .navbar-brand-box {
        padding-right: 18px;
        padding-left: 18px;

        .logo {
            &-text {
                display: none;
            }

            img {
                height: 25px;
            }
        }
    }
}

@media(max-width: 599px) {
    .header-item {
        height: 43px;
        padding: 0;
        background-color: transparent !important;
        overflow: visible !important;
    }

}
