// $trial-height: 40px;
// $white: #FFFFFF;
// $primary: #4f46e5;
// $header-bg: #36394c;
// $header-height: 58px;
// $grid-gutter-width: 24px;
// $navbar-brand-box-width: 240px;

//Core files
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/variables";
@import "./stylesheets/scss/variables";

//Fonts
// @import "custom/fonts/fonts";

//Libraries
@import 'react-redux-toastr/src/styles/index';

// Structure
@import "./stylesheets/scss/custom/structure/general";
@import "./stylesheets/scss/custom/structure/topbar";
@import "./stylesheets/scss/custom/structure/page-head";
// @import "./stylesheets/scss/custom/structure/footer";
@import "./stylesheets/scss/custom/structure/right-sidebar";
@import "./stylesheets/scss/custom/structure/vertical";
@import "./stylesheets/scss/custom/structure/horizontal-nav";
@import "./stylesheets/scss/custom/structure/layouts";

// Components
@import "./stylesheets/scss/custom/components/waves";
@import "./stylesheets/scss/custom/components/avatar";
@import "./stylesheets/scss/custom/components/helper";
@import "./stylesheets/scss/custom/components/preloader";
@import "./stylesheets/scss/custom/components/widgets";
@import "./stylesheets/scss/custom/components/demos";
@import "./stylesheets/scss/custom/components/print";
@import "./stylesheets/scss/custom/components/date-picker";
@import "./stylesheets/scss/custom/components/user-questions";
@import "./stylesheets/scss/custom/components/user-test";
@import "./stylesheets/scss/custom/components/table-data-export";
@import "./stylesheets/scss/custom/components/pro-sidebar";
@import "./src/stylesheets/scss/custom/components/dashboard-stats-card";
@import "./src/stylesheets/scss/custom/components/streaming-capabilities";
@import "./src/stylesheets/scss/custom/components/table";
@import "./src/stylesheets/scss/custom/components/experience-summary";
@import "./src/stylesheets/scss/custom/components/internet-performance";


// Plugins
@import "./stylesheets/scss/custom/plugins/custom-scrollbar";
@import "./stylesheets/scss/custom/plugins/dragula";
@import "./stylesheets/scss/custom/plugins/session-timeout";
@import "./stylesheets/scss/custom/plugins/range-slider";
@import "./stylesheets/scss/custom/plugins/sweatalert2";
@import "./stylesheets/scss/custom/plugins/rating";
@import "./stylesheets/scss/custom/plugins/parsley";
@import "./stylesheets/scss/custom/plugins/color-picker";
@import "./stylesheets/scss/custom/plugins/select2";
@import "./stylesheets/scss/custom/plugins/switch";
@import "./stylesheets/scss/custom/plugins/datepicker";
@import "./stylesheets/scss/custom/plugins/form-editors";
@import "./stylesheets/scss/custom/plugins/form-wizard";
@import "./stylesheets/scss/custom/plugins/responsive-table";
@import "./stylesheets/scss/custom/plugins/table-editable";
@import "./stylesheets/scss/custom/plugins/morris";
@import "./stylesheets/scss/custom/plugins/chartist";
@import "./stylesheets/scss/custom/plugins/apexcharts";
@import "./stylesheets/scss/custom/plugins/flot";
@import "./stylesheets/scss/custom/plugins/sparkline-chart";
@import "./stylesheets/scss/custom/plugins/google-map";
// @import '~react-redux-toastr/src/styles/index';

//Icons
@import "./stylesheets/scss/custom/plugins/icons";

@import "./stylesheets/scss/custom/components/buttons";
@import "./stylesheets/scss/custom/components/card";

//Company
@import "./stylesheets/scss/custom/components/company/athertonfiber-support";
@import "./stylesheets/scss/custom/components/company/nfo-support";

// Pages
@import "./stylesheets/scss/custom/pages/authentication";
@import "./stylesheets/scss/custom/pages/coming-soon";
@import "./stylesheets/scss/custom/pages/extras-pages";

//Custom
@import "./stylesheets/scss/custom-styles.scss";
@import "./stylesheets/scss/custom.scss";

a {
  color: #626ed4;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// .btn {
//   padding: 12px 22px;
//   font-family: Inter;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 18px;
//   color: #4f46e5;
//   background-color: transparent;
//   border-radius: 8px;
//   outline: none;
//   border: none;
//   letter-spacing: .1px;
//   box-shadow: none;

//   &:focus {
//     box-shadow: none;
//   }
// }

// .form-group {
//   margin-bottom: 1rem;
// }

.spinner-border {
  &.text-primary {
    border-color: $primary;
    border-right-color: transparent;

    .sr-only {
      display: none;
    }
  }
}
