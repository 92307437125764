.user-test {
  position: relative;
  width: 100%;
  max-width: 806px;
  padding: 32px;

  > .spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .7);
    z-index: 3;
  }
  &-flex {
    display: flex;
    align-items: center;
  }
  &-block {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    &_border {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  @media(max-width: 599px) {
    padding: 20px;
  }
}
