$violet_color: #4F46E5;

.on-boarding-pop-up {
  padding-bottom: 10px;
  > .mapboxgl-popup-content {
    background: $violet_color;
    color: #fff;
    padding: 25px 12px 12px;
    border-radius: 10px;
    .on-boarding-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .on-boarding-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .on-boarding-step-count {
      font-weight: bold;
      font-size: 14px;
    }
    .on-boaring-next-btn {
      border-color: transparent;
      color: #4F46E5;
      border-radius: 6px;
      padding: 5px 10px;
    }
  }
  > .mapboxgl-popup-close-button {
    color: #fff!important;
    font-size: 20px;
  }
  > .mapboxgl-popup-tip {
    border-top-color: $violet_color!important;
  }
  .mapboxgl-popup-close-button {
    color: #fff!important;
    font-size: 20px;
  }
}
